export default {
    namespaced: true,
    state: {
        breadcrumbValue: null
    },
    getters: {
        getBreadcrumbValue(state) {
            return state.breadcrumbValue;
        }
    },
    mutations: {
        UPDATE_BREADCRUMB_VALUE(state, newBreadcrumbValue) {
            state.breadcrumbValue = newBreadcrumbValue;
        }
    },
    actions: {
        updateBreadcrumbValue({commit}, newBreadcrumbValue) {
            commit('UPDATE_BREADCRUMB_VALUE', newBreadcrumbValue);
        }
    },
}