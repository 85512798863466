export default {
    namespaced: true,
    state: {
        globalLocale: 'uz'
    },
    getters: {
        getGlobalLocale(state) {
            return state.globalLocale;
        }
    },
    mutations: {
        UPDATE_GLOBAL_LOCALE(state, newGlobalLocale) {
            state.globalLocale = newGlobalLocale;
        }
    },
    actions: {
        updateGlobalLocale({commit}, newGlobalLocale) {
            commit('UPDATE_GLOBAL_LOCALE', newGlobalLocale);
        }
    },
}