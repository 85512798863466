<script>
import globalMixin from '@/mixins/globalMixin';

export default {
    name: 'NotifyWarningDialogComponent',
    mixins: [globalMixin],
    data: () => ({
        showNotifyDialog: false,
        notifyClientDtoList: [],
    }),
    methods: {
        showNotifyWarningDialog() {
            this.getNotify();
        },
        closeNotifyWarningDialog() {
            this.showNotifyDialog = false;
        },
        async getNotify() {
            await this.$getMethod(this, '/notarial_act/get_notify').then(notifyResponse => {
                this.notifyClientDtoList = notifyResponse.data;
                this.showNotifyDialog = this.notifyClientDtoList.length > 0;
            }).catch(error => console.error(error));
        },
        routerLink(notifyClientDto) {
            let parentPath = this.isKiosk ? '/kiosk' : '';
            let url;
            if (notifyClientDto.regsystemid === 2) {
                url = '/my_notarial_act/' + notifyClientDto.rid;
            } else if (notifyClientDto.regsystemid === 3 || notifyClientDto.regsystemid === 4) {
                url = '/enotarial_act/' + notifyClientDto.uuid;
            }
            return parentPath + url;
        },
    },
};
</script>

<template>
    <v-dialog
        v-model="showNotifyDialog"
        persistent
        :max-width="$vuetify.breakpoint.mobile ? 100 + '%' : 60 + '%'"
    >
        <v-card>
            <v-card-title class="headline flex justify-center">
                <v-icon color="red" left class="mr-3">mdi-bell</v-icon>
                {{ $t('main.notify_title') }}
            </v-card-title>
            <v-divider class="mb-2"></v-divider>
            <v-card-text class="text-body-1 py-0">
                <v-row dense>
                    <v-col>
                        <ul style="list-style-type: none" class="pl-0">
                            <li v-for="(notifyClientDto, index) in notifyClientDtoList"
                                :key="index"
                                @click="closeNotifyWarningDialog"
                            >
                                <router-link
                                    :to="routerLink(notifyClientDto)"
                                    class="text-decoration-none"
                                    :target="isKiosk? '': '_blank'"
                                >
                                    <v-alert class="ma-0 mb-2 text-center" style="color: white;" :color="index % 2 === 0 ? 'blue' : 'orange'" dense>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :color="index % 2 === 0  ? 'white' : 'white'"
                                                    class="mr-2"
                                                >
                                                    {{
                                                        notifyClientDto.regtypeid === 1 ? 'mdi-walk' : 'mdi-webcam'
                                                    }}
                                                </v-icon>
                                            </template>
                                            <span>{{
                                                    notifyClientDto.regtypeid === 1
                                                        ? $t('notarial_act.my.type_1')
                                                        : $t(
                                                            'notarial_act.my.type_2')
                                                }}</span>
                                        </v-tooltip>
                                        <strong>{{ notifyClientDto.na }}</strong>
                                        <br/>
                                        {{
                                            (notifyClientDto.regtypeid === 2 &&
                                                notifyClientDto.visittime !== null)
                                                ? (notifyClientDto.visitdate + ' (' +
                                                    notifyClientDto.visittime +
                                                    ')')
                                                : ''
                                        }}
                                        <br/>
                                        <v-icon
                                            :color="index % 2 === 0  ? 'white' : 'white'">
                                            mdi-gesture-tap
                                        </v-icon>
                                        {{ $t('notarial_act.my.tap_here') }}
                                        <v-icon
                                            :color="index % 2 === 0  ? 'white' : 'white'"
                                        >
                                            mdi-gesture-tap
                                        </v-icon>
                                    </v-alert>
                                </router-link>
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider class="mb-2"></v-divider>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    class="mr-2"
                    color="red"
                    @click="showNotifyDialog = false"
                >
                    {{ $t('main.notify_ok') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>

</style>