export default {
    namespaced: true,
    state: {
        token: null
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        isAuthenticated(state) {
            return !!state.token;
        }
    },
    mutations: {
        UPDATE_TOKEN(state, newToken){
            state.token = newToken;
        },
        LOGOUT(state){
            state.token = null;
        }
    },
    actions: {
        signIn({commit}, newToken) {
            commit('UPDATE_TOKEN', newToken);
        },
        updateToken({commit}, newToken) {
            commit('UPDATE_TOKEN', newToken);
        },
        logout({commit}) {
            commit('LOGOUT');
        }
    },
}