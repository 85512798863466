/*eslint no-undef: "error"*/

import '@/assets/luna_libs/LunaPass.1.6.0';
import '@/assets/luna_libs/LPMessageRenderer.1.6.0';
import utils from '@/utils/utils';
import constants from '@/utils/constants';
import {ERROR_CODES, ERROR_MESSAGES_KAA, ERROR_MESSAGES_RU, ERROR_MESSAGES_UZ,} from '@/utils/luna/lpErrors';

export const Luna = {
    data: () => ({
        isLoader: true,
        lunapass: null,
        lpMessageRenderer: null,
    }),
    methods: {
        async initLuna(rootNode, locale, breakpoint) {
            console.log('userAgent: ', utils.userAgent());
            const device = utils.userAgent().device;
            this.lunapass = new LunaPass(rootNode, breakpoint, {
                debug: false,
                requestInterval: 300,
                headers: {
                    'session-id': Math.random().toString(36).slice(-7),
                    'heartbeat': 3000,
                },
                video: {
                    facingMode: 'user',
                },
            });
            let ERROR_MESSAGES = ERROR_MESSAGES_RU;
            if (locale === 'uz')
                ERROR_MESSAGES = ERROR_MESSAGES_UZ;
            else if (locale === 'kaa')
                ERROR_MESSAGES = ERROR_MESSAGES_KAA;

            this.lpMessageRenderer = new LPMessageRenderer({
                text: true,
                graphics: true,
                errorMessages: {...ERROR_CODES, ...ERROR_MESSAGES},
            });

            await this.lunapass.connectWS(constants.LUNA_SOCKET);
            await this.lunapass.attachCamera();
            await this.lpMessageRenderer.init(rootNode);

            const onMessage = (frameStatus) => {
                this.lpMessageRenderer.render(frameStatus);
            };

            try {
                return await this.lunapass.checkLiveness(onMessage);
            } catch (error) {
                console.error(error);
            }
        },

        async onStop() {
            if (this.lpMessageRenderer)
                this.lpMessageRenderer.destroy();
            if (this.lunapass) {
                await this.lunapass.disconnectWS();
                await this.lunapass.detachCamera();
            }
        },
    },
    watch: {
        'lunapass.isReady': function () {
            if (this.lunapass.isReady)
                this.isLoader = false;
        },
    },
};