export default {
    namespaced: true,
    state: {
        redirectUrl: null,
    },
    getters: {
        getRedirectUrl(state) {
            return state.redirectUrl;
        },
    },
    mutations: {
        UPDATE_REDIRECT_URL(state, newRedirectUrl) {
            state.redirectUrl = newRedirectUrl;
        },
    },
    actions: {
        updateRedirectUrl({commit}, newRedirectUrl) {
            commit('UPDATE_REDIRECT_URL', newRedirectUrl);
        },
    },
};