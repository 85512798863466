<script>
import globalMixin from '@/mixins/globalMixin';
import AuthDialogComponent from '@/components/dialogs/AuthDialogComponent.vue';
import FaceIdDialogComponent from '@/components/dialogs/FaceIdDialogComponent.vue';

export default {
    name: 'AuthComponent',
    mixins: [globalMixin],
    components: {
        FaceIdDialogComponent,
        AuthDialogComponent,
    },
    data: () => ({
        isLoader: false,
    }),
    mounted() {
        this.$root.$on('showAuthDialog', () => this.showAuthDialog());
        this.$root.$on('checkFaceId', (authDto) => this.checkFaceId(authDto));
    },
    methods: {
        showAuthDialog() {
            if (this.$refs.authDialogComponentRef)
                this.$refs.authDialogComponentRef.showAuthDialog();
        },
        async checkFaceId(authDto) {
            await this.$checkBeforeAuth(authDto).then((result) => {
                this.isLoader = false;
                if (result && this.$refs.faceIdDialogComponentRef)
                    this.$refs.faceIdDialogComponentRef.showFaceIdDialog(authDto);
            }).catch((error) => {
                console.error(error);
                this.isLoader = false;
            });
        },
        login(authDto) {
            this.isLoader = true;
            this.$auth(authDto).then((response) => {
                this.closeDialogs();
                this.isLoader = false;

                let result = response.data;
                if (result.resultCode === 1)
                    this.$emit('showSuccessDialog');
                else if (result.resultCode === 0)
                    this.$emit('showNotificationDialog');
                else
                    this.$emit('showErrorDialog', result.message);
            }).catch((error) => console.log(error));
        },
        timeOutFaceId() {
            this.closeDialogs();
            this.showErrorDialog();
        },
        reRenderComponent() {
            this.$emit('reRenderComponent');
        },
        closeDialogs() {
            if (this.isBrowser && this.$refs.authDialogComponentRef)
                this.$refs.authDialogComponentRef.closeAuthDialog();
            if (this.$refs.faceIdDialogComponentRef)
                this.$refs.faceIdDialogComponentRef.closeFaceIdDialog();
            this.reRenderComponent();
        },
    },

};
</script>

<template>
    <div>
        <v-overlay
            :value="isLoader"
            :opacity="0.7"
            color="white"
        >
            <sync-loader :loading="isLoader" color="#e56e07"></sync-loader>
        </v-overlay>

        <AuthDialogComponent
            ref="authDialogComponentRef"
            @checkFaceId="checkFaceId"
            @close="reRenderComponent"
        />

        <FaceIdDialogComponent
            ref="faceIdDialogComponentRef"
            @checkByPinAndImageResult="login"
            @timeOut="timeOutFaceId"
            @close="reRenderComponent"
        />

    </div>
</template>

<style scoped>

</style>