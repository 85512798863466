<script>
import globalMixin from '@/mixins/globalMixin';
import SimpleKeyboard from '@/components/input/SimpleKeyboard.vue';

export default {
    name: 'AuthDataComponent',
    mixins: [globalMixin],
    components: {SimpleKeyboard},
    data: () => ({
        agreeLoginLoading: false,
        authDto: {
            pin: null,
            passserial: null,
            passnum: null,
            jwt: null,
            image: null,
        },
        keyboards: {
            passserial: false,
            passnum: false,
        },
    }),
    mounted() {
        let routeQuery = this.$route.query;
        if (routeQuery.passserial && routeQuery.passnum) {
            this.authDto.passserial = routeQuery.passserial;
            this.authDto.passnum = routeQuery.passnum;
        }
    },
    computed: {
        // requiredPin() {
        //     return !!this.authDto.pin || this.$t('error_required_pin');
        // },
        // wrongPinLength() {
        //     return (!!this.authDto.pin && this.authDto.pin.length === 14) ||
        //         this.$t('error_wrong_pin_length');
        // },
        requiredPassserial() {
            return !!this.authDto.passserial || this.$t('error_required_passserial');
        },
        wrongPassserialLength() {
            return (!!this.authDto.passserial && this.authDto.passserial.length === 2) ||
                this.$t('error_wrong_passserial_length');
        },
        requiredPassnum() {
            return !!this.authDto.passnum || this.$t('error_required_passnum');
        },
        wrongPassnumLength() {
            return (!!this.authDto.passnum && this.authDto.passnum.length === 7) ||
                this.$t('error_wrong_passnum_length');
        },
        canAuth() {
            return true;
            // return this.requiredPassserial === true && this.wrongPassserialLength === true &&
            //     this.requiredPassnum === true && this.wrongPassnumLength === true;
        },
        // canAuth() {
        //     return this.requiredPin === true && this.wrongPinLength === true &&
        //         this.requiredPassserial === true && this.wrongPassserialLength === true &&
        //         this.requiredPassnum === true && this.wrongPassnumLength === true;
        // },
    },
    methods: {
        clear() {
            this.authDto.pin = null;
            this.authDto.passserial = null;
            this.authDto.passnum = null;
            this.authDto.image = null;
        },
        checkFaceId() {
            this.$root.$emit('checkFaceId', this.authDto);
        },
    },
    watch: {
        'authDto.passserial': function() {
            if (this.authDto.passserial.length === 2)
                this.$refs.passnumRef.focus();
        },
        'authDto.passnum': function() {
            if (this.keyboards.passnum && this.authDto.passnum.length === 7) {
                this.keyboards.passnum = false;
                this.$refs.passnumRef.blur();
            }
        },
    },
};
</script>

<template>
    <div>
        <!--                <span class="required">{{ $t('pin') }}</span>-->
        <!--                <v-text-field-->
        <!--                    v-model="authDto.pin"-->
        <!--                    outlined-->
        <!--                    dense-->
        <!--                    placeholder="00000000000000"-->
        <!--                    v-mask="{type: 'pin'}"-->
        <!--                    :rules="[requiredPin, wrongPinLength]"-->
        <!--                />-->
        <div style="text-align: justify" class="mb-2">{{ $t('auth_passport') }}</div>
        <v-row dense>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="authDto.passserial"
                    class="upper required"
                    :label="$t('auth_passserial')"
                    outlined
                    dense
                    placeholder="AA"
                    v-mask="{type: 'passserial', casing: 'upper'}"
                    :rules="[requiredPassserial, wrongPassserialLength]"
                    @focus="keyboards.passserial = isKiosk"
                    @blur="keyboards.passserial = false"
                >
                    <template v-slot:append>
                        <SimpleKeyboard
                            keyboard-class="passserial"
                            v-show="keyboards.passserial"
                            :input="authDto.passserial"
                            input-length="2"
                            @onChange="(input) => authDto.passserial = input.toUpperCase()"
                        />
                    </template>
                </v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="authDto.passnum"
                    class="required"
                    :label="$t('auth_passnum')"
                    outlined
                    dense
                    ref="passnumRef"
                    placeholder="0000000"
                    v-mask="{type: 'number', length: 7}"
                    :rules="[requiredPassnum, wrongPassnumLength]"
                    @focus="keyboards.passnum = isKiosk"
                    @blur="keyboards.passnum = false"
                >
                    <template v-slot:append>
                        <SimpleKeyboard
                            keyboard-class="passnum"
                            v-show="keyboards.passnum"
                            :input="authDto.passnum"
                            input-type="number"
                            input-length="7"
                            @onChange="(input) => authDto.passnum = input"
                        />
                    </template>
                </v-text-field>
            </v-col>
        </v-row>
        <v-btn
            color="primary"
            block
            style="text-transform: none; font-size: large; font-weight: bold"
            large
            :disabled="!canAuth"
            @click="checkFaceId"
        >
            {{ $t('pass_identification') }}
        </v-btn>
    </div>
</template>

<style scoped>

</style>