(() => {
    var e = {
        566: e => {e.exports = 'data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3csvg version=\'1.1\' id=\'Layer_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 24 24\' style=\'enable-background:new 0 0 24 24;\' xml:space=\'preserve\'%3e %3cpath style=\'fill:none;stroke:%2352ff80;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M12,5v14\'/%3e %3cpath style=\'fill:none;stroke:%2352ff80;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M19,12l-7,7l-7-7\'/%3e %3c/svg%3e';},
        969: e => {e.exports = 'data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3csvg version=\'1.1\' id=\'Layer_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 24 24\' style=\'enable-background:new 0 0 24 24;\' xml:space=\'preserve\'%3e %3cpath style=\'fill:none;stroke:%2352ff80;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M19,12H5\'/%3e %3cpath style=\'fill:none;stroke:%2352ff80;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M12,19l-7-7l7-7\'/%3e %3c/svg%3e';},
        329: e => {e.exports = 'data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3csvg version=\'1.1\' id=\'Layer_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 24 24\' style=\'enable-background:new 0 0 24 24;\' xml:space=\'preserve\'%3e %3cpath style=\'fill:none;stroke:%2300ff44;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M7.8,9.06 c0.38-2.11,1.08-3.97,1.99-5.3c0.91-1.33,1.98-2.06,3.05-2.09c1.07-0.02,2.08,0.67,2.88,1.96c0.8,1.3,1.35,3.13,1.56,5.22 c0.21,2.09,0.08,4.33-0.39,6.39c-0.46,2.05-1.23,3.8-2.18,4.99c-0.95,1.18-2.04,1.74-3.1,1.58c-1.06-0.16-2.03-1.02-2.77-2.45 l-2.49-4.52l0.03,3.75l-0.03-3.75l4.25,3.51\'/%3e %3c/svg%3e';},
        245: e => {e.exports = 'data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3csvg version=\'1.1\' id=\'Layer_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 24 24\' style=\'enable-background:new 0 0 24 24;\' xml:space=\'preserve\'%3e %3cpath style=\'fill:none;stroke:%2300ff44;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M9.89,12.12L7.17,14.9 L6.23,9.02l0.94,5.88l1.41-6.41c0.41-1.99,1.08-3.69,1.91-4.85c0.83-1.16,1.78-1.72,2.7-1.59c0.92,0.13,1.76,0.95,2.4,2.32 c0.64,1.37,1.04,3.23,1.15,5.29c0.1,2.06-0.1,4.22-0.57,6.14c-0.48,1.92-1.2,3.51-2.06,4.52c-0.86,1.01-1.82,1.39-2.72,1.09 s-1.71-1.28-2.29-2.78\'/%3e %3c/svg%3e';},
        80: e => {e.exports = 'data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3csvg version=\'1.1\' id=\'Layer_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 24 24\' style=\'enable-background:new 0 0 24 24;\' xml:space=\'preserve\'%3e %3cpath style=\'fill:none;stroke:%2352ff80;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M5,12h14\'/%3e %3cpath style=\'fill:none;stroke:%2352ff80;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M12,5l7,7l-7,7\'/%3e %3c/svg%3e';},
        907: e => {e.exports = 'data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3csvg version=\'1.1\' id=\'Layer_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 24 24\' style=\'enable-background:new 0 0 24 24;\' xml:space=\'preserve\'%3e %3cpath style=\'fill:none;stroke:%2300ff44;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M1,4v6h6\'/%3e %3cpath style=\'fill:none;stroke:%2300ff44;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M3.51,15 c0.65,1.84,1.88,3.42,3.5,4.5s3.56,1.61,5.5,1.49c1.95-0.11,3.81-0.85,5.3-2.11c1.49-1.26,2.53-2.97,2.96-4.87 c0.43-1.9,0.24-3.89-0.56-5.68c-0.8-1.78-2.15-3.25-3.86-4.2c-1.71-0.94-3.68-1.31-5.61-1.04c-1.93,0.27-3.72,1.16-5.1,2.54L1,10\'/%3e %3c/svg%3e';},
        879: e => {e.exports = 'data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3csvg version=\'1.1\' id=\'Layer_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 24 24\' style=\'enable-background:new 0 0 24 24;\' xml:space=\'preserve\'%3e %3cpath style=\'fill:none;stroke:%2300ff44;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M23,4v6h-6\'/%3e %3cpath style=\'fill:none;stroke:%2300ff44;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M20.49,15 c-0.65,1.84-1.88,3.42-3.51,4.5c-1.63,1.08-3.56,1.6-5.51,1.49c-1.95-0.11-3.81-0.86-5.29-2.12c-1.49-1.26-2.53-2.97-2.96-4.88 c-0.43-1.9-0.23-3.89,0.57-5.68c0.8-1.78,2.16-3.25,3.86-4.19c1.71-0.94,3.68-1.3,5.61-1.03c1.93,0.27,3.72,1.17,5.1,2.55L23,10\'/%3e %3c/svg%3e';},
        707: e => {e.exports = 'data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3csvg version=\'1.1\' id=\'Layer_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 24 24\' style=\'enable-background:new 0 0 24 24;\' xml:space=\'preserve\'%3e %3cpath style=\'fill:none;stroke:%2352ff80;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M12,19V5\'/%3e %3cpath style=\'fill:none;stroke:%2352ff80;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M5,12l7-7l7,7\'/%3e %3c/svg%3e';},
        21: e => {e.exports = 'data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3csvg version=\'1.1\' id=\'Layer_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 24 24\' style=\'enable-background:new 0 0 24 24;\' xml:space=\'preserve\'%3e %3cpath style=\'fill:none;stroke:%2300ff44;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M4.99,13.91 c-1.46-0.68-2.38-1.56-2.61-2.48C2.14,10.52,2.59,9.59,3.65,8.8c1.07-0.79,2.7-1.39,4.65-1.74c1.95-0.33,4.12-0.38,6.16-0.14 c2.05,0.25,3.87,0.78,5.2,1.52c1.32,0.73,2.08,1.63,2.15,2.56c0.07,0.93-0.56,1.84-1.77,2.58c-1.22,0.75-2.96,1.3-4.97,1.57 L8.58,16.1L12,17.45L8.58,16.1l3.84-2.63\'/%3e %3c/svg%3e';},
        440: e => {e.exports = 'data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3csvg version=\'1.1\' id=\'Layer_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 24 24\' style=\'enable-background:new 0 0 24 24;\' xml:space=\'preserve\'%3e %3cpath style=\'fill:none;stroke:%2300ff44;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;\' d=\'M11.68,13.46l3.77,2.72 l-4.09,1.49l4.09-1.49l-6.47-1.12c-2.01-0.32-3.73-0.91-4.93-1.69c-1.2-0.78-1.8-1.7-1.71-2.63c0.09-0.92,0.87-1.8,2.21-2.5 s3.18-1.18,5.23-1.39c2.05-0.19,4.22-0.09,6.16,0.29c1.94,0.39,3.56,1.04,4.61,1.85s1.47,1.76,1.21,2.67 c-0.26,0.91-1.2,1.77-2.67,2.41\'/%3e %3c/svg%3e';},
    }, t = {};

    function n(i) {
        var r = t[i];
        if (void 0 !== r) return r.exports;
        var s = t[i] = {exports: {}};
        return e[i](s, s.exports, n), s.exports;
    }

    n.n = e => {
        var t = e && e.__esModule ? () => e.default : () => e;
        return n.d(t, {a: t}), t;
    }, n.d = (e, t) => {
        for (var i in t) n.o(t, i) && !n.o(e, i) &&
        Object.defineProperty(e, i, {enumerable: !0, get: t[i]});
    }, n.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t), (() => {
        'use strict';

        function e(e, t) {
            (null == t || t > e.length) && (t = e.length);
            for (var n = 0, i = new Array(t); n < t; n++) i[n] = e[n];
            return i;
        }

        function t(t, n) {
            return function(e) {if (Array.isArray(e)) return e;}(t) ||
                function(e, t) {
                    var n = null == e ? null : 'undefined' != typeof Symbol &&
                        e[Symbol.iterator] || e['@@iterator'];
                    if (null != n) {
                        var i, r, s, a, o = [], c = !0, l = !1;
                        try {
                            if (s = (n = n.call(e)).next, 0 === t) {
                                if (Object(n) !== n) return;
                                c = !1;
                            } else for (; !(c = (i = s.call(n)).done) &&
                            (o.push(i.value), o.length !== t); c = !0) ;
                        } catch (e) {l = !0, r = e;} finally {
                            try {
                                if (!c && null != n.return &&
                                    (a = n.return(), Object(a) !== a)) return;
                            } finally {if (l) throw r;}
                        }
                        return o;
                    }
                }(t, n) || function(t, n) {
                    if (t) {
                        if ('string' == typeof t) return e(t, n);
                        var i = Object.prototype.toString.call(t).slice(8, -1);
                        return 'Object' === i && t.constructor &&
                        (i = t.constructor.name), 'Map' === i || 'Set' === i
                            ? Array.from(t)
                            : 'Arguments' === i ||
                            /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(i)
                                ? e(t, n)
                                : void 0;
                    }
                }(t, n) || function() {
                    throw new TypeError(
                        'Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.');
                }();
        }

        function i(e) {
            return i = 'function' == typeof Symbol && 'symbol' ==
            typeof Symbol.iterator
                ? function(e) {return typeof e;}
                : function(e) {
                    return e && 'function' == typeof Symbol && e.constructor ===
                    Symbol && e !== Symbol.prototype
                        ? 'symbol'
                        : typeof e;
                }, i(e);
        }

        function r(e) {
            var t = function(e, t) {
                if ('object' !== i(e) || null === e) return e;
                var n = e[Symbol.toPrimitive];
                if (void 0 !== n) {
                    var r = n.call(e, t || 'default');
                    if ('object' !== i(r)) return r;
                    throw new TypeError(
                        '@@toPrimitive must return a primitive value.');
                }
                return ('string' === t ? String : Number)(e);
            }(e, 'string');
            return 'symbol' === i(t) ? t : String(t);
        }

        function s(e, t) {
            for (var n = 0; n < t.length; n++) {
                var i = t[n];
                i.enumerable = i.enumerable ||
                    !1, i.configurable = !0, 'value' in i &&
                (i.writable = !0), Object.defineProperty(e, r(i.key), i);
            }
        }

        var a = n(566), o = n.n(a), c = n(969), l = n.n(c), h = n(329),
            f = n.n(h), u = n(245), d = n.n(u), B = n(80), p = n.n(B),
            g = n(907), w = n.n(g), v = n(879), x = n.n(v), y = n(707),
            m = n.n(y), k = n(21), b = n.n(k), I = n(440), M = n.n(I), _ = 14,
            j = 15, A = 16, P = 17, S = 18, H = 19, E = 20, G = 21, L = 22,
            R = 23, T = 24, V = 64, O = 65, W = 67, F = 68, X = {
                1: 'Ошибка: код 1',
                8: 'Наведите камеру на лицо',
                9: 'Должно быть одно лицо',
                34: 'Ошибка: код 34',
                2: 'Ошибка: код 2',
                3: 'Изображение размыто',
                29: 'Ошибка: код 29',
                30: 'Низкое качество кадра',
                4: 'Нужно меньше света',
                6: 'Устраните неравномерное освещение',
                7: 'Устраните блики',
                5: 'Нужно больше света',
                10: 'Ошибка: код 10',
                11: 'Ошибка: код 11',
                12: 'Ближе к камере',
                13: 'Ближе к камере',
                14: 'Камеру ниже',
                15: 'Камеру левее',
                16: 'Камеру выше',
                17: 'Камеру правее',
                18: 'Ошибка: код 18',
                19: 'Поверните лицо вправо',
                20: 'Поверните лицо влево',
                21: 'Голова слишком наклонена вправо',
                22: 'Голова слишком наклонена влево',
                23: 'Поднимите подбородок',
                24: 'Опустите подбородок',
                25: 'Ошибка: код 25',
                26: 'Уберите перекрытие лица',
                27: 'Ошибка: код 27',
                28: 'Снимите очки',
                31: 'Ошибка: код 31',
                32: 'Продолжайте фотографирование',
                33: 'Низкое качество изображения лица',
                37: 'Продолжайте',
                38: 'Камеру дальше от лица',
                40: 'Ошибка 40',
                50: 'Ошибка 50',
                51: 'Откройте глаза',
                60: 'Ошибка 60',
                61: 'Откройте глаза',
                62: 'Закройте глаза',
                63: 'Смотрите в камеру прямо',
                64: 'Поверните голову направо',
                65: 'Поверните голову налево',
                66: 'Смотрите в камеру прямо',
                67: 'Поднимите подбородок',
                68: 'Опустите подбородок',
            };

        function Y(e, t) {
            var n = Math.abs(e - t);
            return Math.floor(.12 * n);
        }

        function D(e) {
            return e + Math.abs(15 * Math.sin(
                (Math.floor((new Date).getTime() / 6 % 360) - 180) * Math.PI /
                180));
        }

        function q(e) {
            return e + Math.abs(15 * Math.cos(
                (Math.floor((new Date).getTime() / 6 % 360) - 180) * Math.PI /
                180));
        }

        function C() {
            var e = Math.abs(Math.sin(
                (Math.floor((new Date).getTime() / 2 % 360) - 180) * Math.PI /
                180));
            return e > .5 ? e : .5;
        }

        function U(e, t) {
            var n = Object.keys(e);
            if (Object.getOwnPropertySymbols) {
                var i = Object.getOwnPropertySymbols(e);
                t && (i = i.filter((function(t) {
                    return Object.getOwnPropertyDescriptor(e, t).enumerable;
                }))), n.push.apply(n, i);
            }
            return n;
        }

        function N(e) {
            for (var t = 1; t < arguments.length; t++) {
                var n = null != arguments[t]
                    ? arguments[t]
                    : {};
                t % 2 ? U(Object(n), !0).forEach((function(t) {
                    var i, s, a;
                    i = e, s = t, a = n[t], (s = r(s)) in i
                        ? Object.defineProperty(i, s, {
                            value: a,
                            enumerable: !0,
                            configurable: !0,
                            writable: !0,
                        })
                        : i[s] = a;
                })) : Object.getOwnPropertyDescriptors
                    ? Object.defineProperties(e,
                        Object.getOwnPropertyDescriptors(n))
                    : U(Object(n)).
                        forEach((function(t) {
                            Object.defineProperty(e, t,
                                Object.getOwnPropertyDescriptor(n, t));
                        }));
            }
            return e;
        }

        var $ = 45, z = 60, J = 'vllunapass', K = function(e, t) {
            return {
                x: 0,
                y: 0,
                w: 0,
                h: 0,
                nX: e / 2 - 90,
                nY: t / 2 - 125,
                nW: 180,
                nH: 250,
                sX: 1,
                sY: 1,
            };
        }, Q = function() {
            function e() {
                var t, n, i = arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : {};
                !function(e, t) {
                    if (!(e instanceof t)) throw new TypeError(
                        'Cannot call a class as a function');
                }(this, e), this.imgPaths = {
                    up: m(),
                    down: o(),
                    left: l(),
                    right: p(),
                    yawLeft: b(),
                    yawRight: M(),
                    pitchBack: f(),
                    pitchFront: d(),
                    rollLeft: w(),
                    rollRight: x(),
                }, this.images = {}, this.params = {
                    graphics: null === (t = i.graphics) || void 0 === t || t,
                    text: null === (n = i.text) || void 0 === n || n,
                    errorMessages: N(N({}, X), i.errorMessages),
                }, this.render = this.render.bind(
                    this), this.init = this.init.bind(
                    this), this.destroy = this.destroy.bind(this);
                    // console.info('LPMessageRenderer '.concat('1.0.3'));
            }

            var n, i, r;
            return n = e, i = [
                {
                    key: 'init',
                    value: function(e) {
                        var t = e.querySelector('#'.concat(J, '-wrapper')) || e;
                        this.FRAME_WIDTH = t.dataset.sourceWidth ||
                            t.clientWidth, this.FRAME_HEIGHT = t.dataset.sourceHeight ||
                            t.clientHeight, this.dpr = window.devicePixelRatio, this.canvasInfo = document.createElement(
                            'canvas'), this.canvasInfo.id = ''.concat(J,
                            '-canvasInfo'), this.canvasInfo.style.position = 'absolute', this.canvasInfo.style.top = '50%', this.canvasInfo.style.left = '50%', this.canvasInfo.style.transform = 'translate(-50%, -50%)', this.canvasInfo.width = this.FRAME_WIDTH *
                            this.dpr, this.canvasInfo.height = this.FRAME_HEIGHT *
                            this.dpr, t.appendChild(
                            this.canvasInfo), this.canvasInfo.style.width = ''.concat(
                            this.FRAME_WIDTH,
                            'px'), this.canvasInfo.style.height = ''.concat(
                            this.FRAME_HEIGHT,
                            'px'), this.ctxInfo = this.canvasInfo.getContext(
                            '2d'), this.ctxInfo.scale(this.dpr,
                            this.dpr), this.faceBB = K(this.FRAME_WIDTH,
                            this.FRAME_HEIGHT), this._loadImages();
                    },
                },
                {
                    key: 'destroy',
                    value: function() {
                        this.ctxInfo.clearRect(0, 0, this.canvasInfo.width,
                            this.canvasInfo.height), this.faceBB = K(
                            this.FRAME_WIDTH, this.FRAME_HEIGHT), this.reqId &&
                        cancelAnimationFrame(this.reqId), this.canvasInfo &&
                        this.canvasInfo.remove();
                    },
                },
                {
                    key: '_loadImages', value: function() {
                        var e = this;
                        Object.entries(this.imgPaths).
                            forEach((function(n) {
                                var i = t(n, 2), r = i[0], s = i[1];
                                new Promise((function(e, t) {
                                    var n = new Image;
                                    n.addEventListener('load', (function() {
                                        return e(n);
                                    })), n.addEventListener('error',
                                        (function(e) {return t(e);})), n.src = s;
                                })).then((function(t) {e.images[r] = t;}));
                            }));
                    },
                },
                {
                    key: 'render', value: function(e) {
                        var t, n = this;
                        cancelAnimationFrame(this.reqId);
                        var i = e.errors, r = e.details, s = e.isOk,
                            a = null == r || null ===
                            (t = r.faceFramePosition) || void 0 === t
                                ? void 0
                                : t.faceRect;
                        this.ctxInfo.clearRect(0, 0, this.canvasInfo.width,
                            this.canvasInfo.height), i && this.params.text &&
                        this._renderText(null == i ? void 0 : i[0]), a &&
                        this.params.graphics &&
                        this._renderFaceRect(a, i && i), s ||
                        (this.reqId = requestAnimationFrame(
                            (function() {return n.render(e);})));
                    },
                },
                {
                    key: '_renderText',
                    value: function() {
                        var e = arguments.length > 0 && void 0 !== arguments[0]
                            ? arguments[0]
                            : null;
                        if (null !== e) {
                            this.ctxInfo.font = '18px Georgia', this.ctxInfo.fillStyle = 'rgba(100, 100, 100, .5)';
                            var t, n, i, r, s, a, o = this.ctxInfo.measureText(
                                    this.params.errorMessages[e]),
                                c = o.actualBoundingBoxLeft +
                                    o.actualBoundingBoxRight,
                                l = o.actualBoundingBoxAscent +
                                    o.actualBoundingBoxDescent;
                            (t = this.ctxInfo, n = this.FRAME_WIDTH / 2 - c /
                                2 - 10, i = 10, r = c + 20, s = l +
                                20, a = 8, r < 2 * a && (a = r / 2), s < 2 *
                            a && (a = s / 2), t.beginPath(), t.moveTo(n + a,
                                i), t.arcTo(n + r, i, n + r, i + s, a), t.arcTo(
                                n + r, i + s, n, i + s, a), t.arcTo(n, i + s, n,
                                i, a), t.arcTo(n, i, n + r, i,
                                a), t.closePath(), t).fill(), this.ctxInfo.textAlign = 'center', this.ctxInfo.fillStyle = e
                                ? 'white'
                                : '#00FF00', this.ctxInfo.fillText(
                                this.params.errorMessages[e],
                                this.FRAME_WIDTH / 2,
                                20 + o.actualBoundingBoxAscent);
                        }
                    },
                },
                {
                    key: '_renderFaceRect', value: function() {
                        var e, t, n, i, r,
                            s = arguments.length > 0 && void 0 !== arguments[0]
                                ? arguments[0]
                                : null,
                            a = arguments.length > 1 && void 0 !== arguments[1]
                                ? arguments[1]
                                : null;
                        s && (this.faceBB.x = Math.floor(
                            s.x), this.faceBB.y = Math.floor(
                            s.y), this.faceBB.w = Math.floor(
                            s.width), this.faceBB.h = Math.floor(
                            s.height)), this.faceBB.sX = Y(this.faceBB.x,
                            this.faceBB.nX), this.faceBB.sY = Y(this.faceBB.y,
                            this.faceBB.nY), this.faceBB.nX = this.faceBB.x >
                        this.faceBB.nX
                            ? this.faceBB.nX + this.faceBB.sX
                            : this.faceBB.x < this.faceBB.nX
                                ? this.faceBB.nX - this.faceBB.sX
                                : this.faceBB.x, this.faceBB.nY = this.faceBB.y >
                        this.faceBB.nY
                            ? this.faceBB.nY + this.faceBB.sY
                            : this.faceBB.y < this.faceBB.nY
                                ? this.faceBB.nY - this.faceBB.sY
                                : this.faceBB.y, this.faceBB.nW = this.faceBB.w >
                        this.faceBB.nW ? this.faceBB.nW + 1 : this.faceBB.w <
                        this.faceBB.nW
                            ? this.faceBB.nW - 1
                            : this.faceBB.w, this.faceBB.nH = this.faceBB.h >
                        this.faceBB.nH ? this.faceBB.nH + 1 : this.faceBB.h <
                        this.faceBB.nH
                            ? this.faceBB.nH - 1
                            : this.faceBB.h, e = this.ctxInfo, t = this.faceBB.nX, n = this.faceBB.nY, i = this.faceBB.nW, r = this.faceBB.nH, e.beginPath(), e.lineWidth = '3', e.strokeStyle = '#52ff80B3', e.moveTo(
                            t + 5, n), e.arcTo(t + i, n, t + i, n + r,
                            5), e.arcTo(t + i, n + r, t, n + r, 5), e.arcTo(t,
                            n + r, t, n, 5), e.arcTo(t, n, t + i, n,
                            5), e.closePath(), e.stroke(), a && a.includes(_) &&
                        this._arrowDown(this.ctxInfo, this.faceBB.nX,
                            this.faceBB.nY, this.faceBB.nW,
                            this.faceBB.nH), a && a.includes(j) &&
                        this._arrowLeft(this.ctxInfo, this.faceBB.nX,
                            this.faceBB.nY, this.faceBB.nW,
                            this.faceBB.nH), a && a.includes(A) &&
                        this._arrowUp(this.ctxInfo, this.faceBB.nX,
                            this.faceBB.nY, this.faceBB.nW,
                            this.faceBB.nH), a && a.includes(P) &&
                        this._arrowRight(this.ctxInfo, this.faceBB.nX,
                            this.faceBB.nY, this.faceBB.nW,
                            this.faceBB.nH), a &&
                        (a.includes(H) || a.includes(V)) && !a.includes(A) &&
                        this._yawRight(this.ctxInfo, this.faceBB.nX,
                            this.faceBB.nY, this.faceBB.nW,
                            this.faceBB.nH), a &&
                        (a.includes(E) || a.includes(O)) && !a.includes(A) &&
                        this._yawLeft(this.ctxInfo, this.faceBB.nX,
                            this.faceBB.nY, this.faceBB.nW,
                            this.faceBB.nH), a && a.includes(G) &&
                        !a.includes(A) && !a.includes(R) &&
                        this._rotateLeft(this.ctxInfo, this.faceBB.nX,
                            this.faceBB.nY, this.faceBB.nW,
                            this.faceBB.nH), a && a.includes(L) &&
                        !a.includes(A) && !a.includes(R) &&
                        this._rotateRight(this.ctxInfo, this.faceBB.nX,
                            this.faceBB.nY, this.faceBB.nW,
                            this.faceBB.nH), a &&
                        (a.includes(R) || a.includes(W)) && !a.includes(P) &&
                        this._pitchBack(this.ctxInfo, this.faceBB.nX,
                            this.faceBB.nY, this.faceBB.nW,
                            this.faceBB.nH), a &&
                        (a.includes(T) || a.includes(F)) && !a.includes(P) &&
                        this._pitchFront(this.ctxInfo, this.faceBB.nX,
                            this.faceBB.nY, this.faceBB.nW, this.faceBB.nH);
                    },
                },
                {
                    key: '_arrowLeft',
                    value: function(e, t, n, i, r) {
                        var s = n + r / 2 - 22.5;
                        this.images.left &&
                        e.drawImage(this.images.left, D(t - z), s, $, $);
                    },
                },
                {
                    key: '_arrowRight',
                    value: function(e, t, n, i, r) {
                        var s = n + r / 2 - 22.5;
                        this.images.right &&
                        e.drawImage(this.images.right, q(t + i + 10), s, $, $);
                    },
                },
                {
                    key: '_arrowUp',
                    value: function(e, t, n, i) {
                        var r = t + i / 2 - 22.5;
                        this.images.up &&
                        e.drawImage(this.images.up, r, D(n - z), $, $);
                    },
                },
                {
                    key: '_arrowDown',
                    value: function(e, t, n, i, r) {
                        var s = t + i / 2 - 22.5;
                        this.images.down &&
                        e.drawImage(this.images.down, s, q(n + r + 10), $, $);
                    },
                },
                {
                    key: '_pitchBack',
                    value: function(e, t, n, i, r) {
                        var s = n + r / 2 - 22.5;
                        this.images.pitchBack &&
                        (e.save(), e.globalAlpha = C(), e.drawImage(
                            this.images.pitchBack, t + i + 10, s, $,
                            $), e.restore());
                    },
                },
                {
                    key: '_pitchFront',
                    value: function(e, t, n, i, r) {
                        var s = n + r / 2 - 22.5;
                        this.images.pitchFront &&
                        (e.save(), e.globalAlpha = C(), e.drawImage(
                            this.images.pitchFront, t + i + 10, s, $,
                            $), e.restore());
                    },
                },
                {
                    key: '_rotateLeft',
                    value: function(e, t, n, i) {
                        var r = t + i / 2 - 22.5;
                        this.images.rollLeft &&
                        (e.save(), e.globalAlpha = C(), e.drawImage(
                            this.images.rollLeft, r, n - z, $, $), e.restore());
                    },
                },
                {
                    key: '_rotateRight',
                    value: function(e, t, n, i) {
                        var r = t + i / 2 - 22.5;
                        this.images.rollRight &&
                        (e.save(), e.globalAlpha = C(), e.drawImage(
                            this.images.rollRight, r, n - z, $, $), e.restore());
                    },
                },
                {
                    key: '_yawLeft',
                    value: function(e, t, n, i) {
                        var r = t + i / 2 - 22.5;
                        this.images.yawLeft &&
                        (e.save(), e.globalAlpha = C(), e.drawImage(
                            this.images.yawLeft, r, n - z, $, $), e.restore());
                    },
                },
                {
                    key: '_yawRight',
                    value: function(e, t, n, i) {
                        var r = t + i / 2 - 22.5;
                        this.images.yawRight &&
                        (e.save(), e.globalAlpha = C(), e.drawImage(
                            this.images.yawRight, r, n - z, $, $), e.restore());
                    },
                }], i && s(n.prototype, i), r && s(n, r), Object.defineProperty(
                n, 'prototype', {writable: !1}), e;
        }();
        window.LPMessageRenderer = Q;
    })();
})();
