import moment from 'moment';
import i18n from '@/i18n';
import DeviceDetector from 'device-detector-js';
import sunEditor from 'suneditor';
import 'suneditor/src/assets/css/suneditor.css';
import 'suneditor/src/assets/css/suneditor-contents.css';
import errorMessage from '@/utils/messages/errorMessage';

let symbolTableUZ = {
    'А': 'A',
    'Ь': '',
    'ь': '',
    'а': 'a',
    'Б': 'B',
    'б': 'b',
    'Ч': 'Ch',
    'ч': 'ch',
    'Д': 'D',
    'д': 'd',
    'Е': 'E',
    'е': 'e',
    'Ф': 'F',
    'ф': 'f',
    'Г': 'G',
    'г': 'g',
    'Ғ': 'G‘',
    'ғ': 'g‘',
    'Ҳ': 'H',
    'ҳ': 'h',
    'И': 'I',
    'и': 'i',
    'Ж': 'J',
    'ж': 'j',
    'К': 'K',
    'к': 'k',
    'Қ': 'Q',
    'қ': 'q',
    'Л': 'L',
    'л': 'l',
    'М': 'M',
    'м': 'm',
    'Н': 'N',
    'н': 'n',
    'нг': 'ng',
    'О': 'O',
    'о': 'o',
    'Ў': 'O‘',
    'ў': 'o‘',
    'П': 'P',
    'п': 'p',
    'Р': 'R',
    'р': 'r',
    'С': 'S',
    'с': 's',
    'Ш': 'Sh',
    'ш': 'sh',
    'Т': 'T',
    'т': 't',
    'У': 'U',
    'у': 'u',
    'В': 'V',
    'в': 'v',
    'Х': 'X',
    'х': 'x',
    'Й': 'Y',
    'й': 'y',
    'З': 'Z',
    'з': 'z',
    'Ъ': '’',
    'ъ': '’',
    'Ё': 'Yo',
    'ё': 'yo',
    'Ю': 'Yu',
    'ю': 'yu',
    'Я': 'Ya',
    'я': 'ya',
    'Э': 'E',
    'э': 'e',
    'Ы': 'I',
    'ы': 'i',
    'Щ': 'SH',
    'щ': 'sh',
    'Ц': 'Ts',
    'ц': 'ts',
};
let DG_POWER = 10;
let TAUSEND = 1000;
let aPower = [
    ['0', '', '', ''],
    ['1', 'тысяча ', 'тысячи ', 'тысяч '],
    ['0', 'миллион ', 'миллиона ', 'миллионов '],
    ['0', 'миллиард ', 'миллиарда ', 'миллиардов '],
    ['0', 'триллион ', 'триллиона ', 'триллионов '],
    ['0', 'квадриллион ', 'квадриллиона ', 'квадриллионов '],
];
let uzPower = [
    ['0', '', '', ''],
    ['1', 'минг ', 'минг ', 'минг '],
    ['0', 'миллион ', 'миллион ', 'миллион '],
    ['0', 'миллиард ', 'миллиард ', 'миллиард '],
    ['0', 'триллион ', 'триллион ', 'триллион '],
    ['0', 'квадриллион ', 'квадриллион ', 'квадриллион '],
];
let digit = [
    ['', '', 'десять ', '', ''],
    ['один ', 'одна ', 'одиннадцать ', 'десять ', 'сто '],
    ['два ', 'две ', 'двенадцать ', 'двадцать ', 'двести '],
    ['три ', 'три ', 'тринадцать ', 'тридцать ', 'триста '],
    ['четыре ', 'четыре ', 'четырнадцать ', 'сорок ', 'четыреста '],
    ['пять ', 'пять ', 'пятнадцать ', 'пятьдесят ', 'пятьсот '],
    ['шесть ', 'шесть ', 'шестнадцать ', 'шестьдесят ', 'шестьсот '],
    ['семь ', 'семь ', 'семнадцать ', 'семьдесят ', 'семьсот '],
    ['восемь ', 'восемь ', 'восемнадцать ', 'восемьдесят ', 'восемьсот '],
    ['девять ', 'девять ', 'девятнадцать ', 'девяносто ', 'девятьсот '],
];
let uzDigit = [
    ['', '', 'ўн ', '', ''],
    ['бир ', 'бир ', 'ўн бир ', 'ўн ', 'бир юз '],
    ['икки ', 'икки ', 'ўн икки ', 'йигирма ', 'икки юз '],
    ['уч ', 'уч ', 'ўн уч ', 'ўттиз ', 'уч юз '],
    ['тўрт ', 'тўрт ', 'ўн тўрт ', 'қирқ ', 'тўрт юз '],
    ['беш ', 'беш ', 'ўн беш ', 'эллик ', 'беш юз '],
    ['олти ', 'олти ', 'ўн олти ', 'олтмиш ', 'олти юз '],
    ['етти ', 'етти ', 'ўн етти ', 'етмиш ', 'етти юз '],
    ['саккиз ', 'саккиз ', 'ўн саккиз ', 'саксон ', 'саккиз юз '],
    ['тўққиз ', 'тўққиз ', 'ўн тўққиз ', 'тўқсон ', 'тўққиз юз '],
];

export default {
    today: function() {
        return moment().format('DD.MM.YYYY');
    },
    currentYear: function() {
        return moment().year();
    },
    getDate: function(dateStr) {
        return moment(dateStr, 'DD.MM.YYYY');
    },
    getDatePart: function(date, part) {
        return moment(date, 'DD.MM.YYYY').format(part);
    },
    formatDate: function(date) {
        if (date != null)
            return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },
    formatDateTime: function(date) {
        return moment(date).format('DD.MM.YYYY HH:mm');
    },
    formatDateToDDMMYYYYHHmmss(date) {
        return moment(date).format('DD.MM.YYYY HH:mm:ss');
    },
    encodingToUzbekLatin: function(strToEncode) {
        let resultString = '';
        let beforeSymbolIsGlasnaya = false;
        for (let i = 0; i < strToEncode.length; i++) {
            if (i !== 0) {
                /* проверить предыдущая буква была ГЛАСНАЯ или НЕГЛАСНАЯ */
                let beforesymbol = strToEncode.substr(i - 1, 1).toLowerCase();
                beforeSymbolIsGlasnaya = beforesymbol === 'у' ||
                    beforesymbol === 'е' || beforesymbol === 'а'
                    || beforesymbol === 'о' || beforesymbol === 'э' ||
                    beforesymbol === 'я'
                    || beforesymbol === 'и' || beforesymbol === 'ю';
            }
            if (strToEncode.substr(i, 1) === ' ')
                resultString += ' ';
            else {
                if (strToEncode.substr(i, 1) === 'Ц' &&
                    (i === 0 || strToEncode.substr(i - 1, 1) === ' ') &&
                    !beforeSymbolIsGlasnaya)
                    resultString += 'S';
                else if (strToEncode.substr(i, 1) === 'ц' &&
                    (i === 0 || strToEncode.substr(i - 1, 1) === ' ') &&
                    !beforeSymbolIsGlasnaya)
                    resultString += 's';
                else if (strToEncode.substr(i, 1) === 'Е' &&
                    (i === 0 || strToEncode.substr(i - 1, 1) === ' ' ||
                        beforeSymbolIsGlasnaya))
                    resultString += 'Ye';
                else if (strToEncode.substr(i, 1) === 'е' &&
                    (i === 0 || strToEncode.substr(i - 1, 1) === ' ' ||
                        beforeSymbolIsGlasnaya))
                    resultString += 'ye';
                else {
                    if (strToEncode.substr(i, 1) in symbolTableUZ)
                        resultString += symbolTableUZ[strToEncode.substr(i, 1)];
                    else
                        resultString += strToEncode.substr(i, 1);
                }
            }
        }
        return resultString;
    },
    doubleFormatter: function(num) {
        if (num != null)
            return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        else return '';
    },
    capitalize: function(str) {
        let splitEnter = str.split(' ');
        let capitalized;
        for (let i = 0; i < splitEnter.length; i++) {
            capitalized = splitEnter[i].charAt(0).toUpperCase();
            splitEnter[i] = capitalized + splitEnter[i].substr(1).toLowerCase();
        }
        return splitEnter.join(' ');
    },
    sunEditor: function(editorContent, elementId, canEdit = false) {
        document.getElementById(elementId).value = editorContent;

        if (document.getElementById('suneditor_' + elementId) !== null)
            document.getElementById('suneditor_' + elementId).remove();

        let editor = sunEditor.create(document.getElementById(elementId), {
            maxWidth: '99%',
        });
        editor.readOnly(!canEdit);

        if (!canEdit) {
            let suneditorElementChildren = document.getElementById(
                'suneditor_' + elementId).children[0].children;
            for (const element of suneditorElementChildren) {
                if (element.className === 'se-toolbar sun-editor-common')
                    element.style.display = 'none';
            }
        }

        let elements = document.getElementsByClassName('se-resizing-bar');
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
        return editor;
    },
    numberToWordsRu(sum) {
        sum = parseInt(sum);
        let i;
        let mny;
        let result = '';
        let divisor; // делитель
        let psum = sum;
        let one = 1;
        let four = 2;
        let many = 3;

        let hun = 4;
        let dec = 3;
        let dec2 = 2;

        if (sum === 0)
            return 'ноль ';
        if (sum < 0) {
            result += 'минус ';
            psum = parseInt(psum.toString().replace('-', ''));
        }

        for (i = 0, divisor = 1; i < DG_POWER - 1; i++) {
            divisor = divisor * TAUSEND;
            if (sum < divisor) {
                i++;
                break;
            }
        }

        for (; i >= 0; i--) {
            mny = parseInt(psum / divisor);
            psum = psum % divisor;
            if (mny === 0) {
                if (i === 0) {
                    result += aPower[i][one];
                }
            } else {
                if (mny >= 100) {
                    result += digit[parseInt(mny / 100)][hun];
                    mny %= 100;
                }
                if (mny >= 20) {
                    result += digit[parseInt(mny / 10)][dec];
                    mny %= 10;
                }
                if (mny >= 10) {
                    result += digit[mny - 10][dec2];
                } else {
                    if (mny >= 1)
                        result += digit[mny]['0' === aPower[i][0] ? 0 : 1];
                }
                switch (mny) {
                    case 1:
                        result += aPower[i][one];
                        break;
                    case 2:
                    case 3:
                    case 4:
                        result += aPower[i][four];
                        break;
                    default:
                        result += aPower[i][many];
                        break;
                }
            }
            divisor = divisor / TAUSEND;
        }
        return result.toString();
    },
    numberToWordsUZ(sum) {
        let i;
        let mny;
        let result = '';
        let divisor; // делитель
        let psum = sum;
        let one = 1;
        let four = 2;
        let many = 3;

        let hun = 4;
        let dec = 3;
        let dec2 = 2;

        if (sum === 0)
            return 'ноль ';
        if (sum < 0) {
            result += 'минус ';
            psum = parseInt(psum.toString().replace('-', ''));
        }

        for (i = 0, divisor = 1; i < DG_POWER - 1; i++) {
            divisor = divisor * TAUSEND;
            if (sum < divisor) {
                i++;
                break;
            }
        }

        for (; i >= 0; i--) {
            mny = parseInt(psum / divisor);
            psum = psum % divisor;
            if (mny === 0) {
                if (i === 0) {
                    result += uzPower[i][one];
                }
            } else {
                if (mny >= 100) {
                    result += uzDigit[parseInt(mny / 100)][hun];
                    mny %= 100;
                }
                if (mny >= 20) {
                    result += uzDigit[parseInt(mny / 10)][dec];
                    mny %= 10;
                }
                if (mny >= 10) {
                    result += uzDigit[mny - 10][dec2];
                } else {
                    if (mny >= 1)
                        result += uzDigit[mny]['0' === aPower[i][0] ? 0 : 1];
                }
                switch (mny) {
                    case 1:
                        result += uzPower[i][one];
                        break;
                    case 2:
                    case 3:
                    case 4:
                        result += uzPower[i][four];
                        break;
                    default:
                        result += uzPower[i][many];
                        break;
                }
            }
            divisor = divisor / TAUSEND;
        }
        return result.toString();
    },
    numberToWordsSum(sum, locale) {
        sum = parseFloat(sum);
        let result = '';
        let remeinder;
        if (locale === 'ru') {
            result = this.numberToWordsRu(sum);
            result += 'сум ';
            remeinder = this.numberToWordsRu((sum - parseInt(sum)) * 100);
            result += remeinder + 'тийин';
        } else if (locale === 'uz') {
            result = this.numberToWordsUZ(sum);
            result += 'сўм ';
            remeinder = this.numberToWordsUZ((sum - parseInt(sum)) * 100);
            result += remeinder + 'тийин';
        }
        return result.toUpperCase();
    },
    async checkEnumerateDevices() {
        let hasWebcam = false;
        let hasMicrophone = false;
        await navigator.mediaDevices.enumerateDevices().then((devices) => {
            devices.forEach(device => {
                if (device.kind === 'videoinput')
                    hasWebcam = true;
                if (device.kind === 'audioinput')
                    hasMicrophone = true;
            });
        }).then(() => {
            if (!hasWebcam)
                errorMessage(i18n.t('notarial_act.video.has_not_webcam'));
            if (!hasMicrophone)
                errorMessage(i18n.t('notarial_act.video.has_not_microphone'));
        });
        return [hasWebcam, hasMicrophone];
    },
    async accessWebcamAndMicrophone(audio = false) {
        return await navigator.mediaDevices.getUserMedia(
            {video: this.videoConstraints(), audio: audio}).
            then(() => true).
            catch((error) => {
                if (error.name === 'OverconstrainedError')
                    errorMessage(i18n.t('notarial_act.video.minimal_resolution'));
                else if (['NotReadableError', 'TrackStartError'].includes(error.name))
                    errorMessage(i18n.t('notarial_act.video.not_readable_error'));
                else if (['NotAllowedError', 'PermissionDeniedError'].includes(error.name))
                    errorMessage(i18n.t('notarial_act.video.permission_denied_error'));
                else
                    errorMessage(i18n.t('notarial_act.video.unknown_error'));
                return false;
            }).finally(() => this.stopAllMediaDevices());
    },
    async stopAllMediaDevices() {
        await navigator.mediaDevices.getUserMedia({video: true, audio: true}).
            then((stream) => {
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            });
    },
    videoConstraints() {
        return {width: {min: 640}, height: {min: 480}};
    },
    userAgent() {
        const deviceDetector = new DeviceDetector();
        return deviceDetector.parse(window.navigator.userAgent);
    },
    isMobile() {
        return this.userAgent().device.type === 'mobile';
    },
};