<script>
export default {
    name: 'ErrorDialogComponent',
    data: () => ({
        showDialog: false,
        content: null,
    }),
    methods: {
        showErrorDialog(content) {
            if (content)
                this.content = content;
            else
                this.content = this.$t('auth.error.content');
            this.showDialog = true;
        },
        closeErrorDialog() {
            this.showDialog = false;
            this.$emit('confirm');
        },
    },
};
</script>

<template>
    <v-dialog
        v-model="showDialog"
        :max-width="$vuetify.breakpoint.mobile ? '90%' : '30%'"
        persistent
    >
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col class="text-center">
                        <v-icon color="red" size="100">
                            mdi-close-circle
                        </v-icon>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-subtitle>
                <v-row>
                    <v-col class="text-center">
                        <span class="text-h4">{{ $t('auth.error.title') }}</span>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col class="text-center">
                        <span style="font-size: large;">{{ content }}</span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    class="mb-4"
                    color="red"
                    block
                    style="text-transform: none;  font-size: large; color: white;"
                    large
                    @click="closeErrorDialog"
                >
                    {{ $t('auth.error.btn') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
</style>