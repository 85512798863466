export default {
    namespaced: true,
    state: {
        mode: 'browser',
    },
    getters: {
        getMode(state) {
            return state.mode;
        },
    },
    mutations: {
        UPDATE_MODE(state, newMode) {
            state.mode = newMode;
        },
    },
    actions: {
        updateMode({commit}, newMode) {
            commit('UPDATE_MODE', newMode);
        },
    },
};