<template>
    <v-dialog
        v-model="showDialog"
        :max-width="$vuetify.breakpoint.mobile ? '90%' : '30%'"
        persistent
    >
        <v-card>
            <v-card-title>
                <v-row justify="end">
                    <v-col cols="2"></v-col>
                    <v-col class="text-h5 font-weight-bold text-center">
                        {{ $t('title_auth') }}
                    </v-col>
                    <v-col cols="2" class="text-right">
                        <v-icon
                            @click="closeAuthDialog"
                        >
                            mdi-close-thick
                        </v-icon>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-title>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <v-avatar color="primary" size="136">
                            <v-avatar color="white" size="128">
                                <v-avatar color="primary" size="120">
                                    <v-icon color="white" size="120">
                                        mdi-account-tie
                                    </v-icon>
                                </v-avatar>
                            </v-avatar>
                        </v-avatar>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <AuthDataComponent
                    ref="authDataComponentRef"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import AuthDataComponent from '@/components/auth/AuthDataComponent.vue';

export default {
    name: 'AuthDialogComponent',
    components: {AuthDataComponent},
    data: () => ({
        showDialog: false,
    }),
    methods: {
        showAuthDialog() {
            this.showDialog = true;
            // this.$refs.authDataComponentRef.clear();
            let routeQuery = this.$route.query;
            if (routeQuery.passserial && routeQuery.passnum) {
                this.authDto.passserial = routeQuery.passserial;
                this.authDto.passnum = routeQuery.passnum;
            }
        },
        closeAuthDialog() {
            this.showDialog = false;
            this.$refs.authDataComponentRef.clear();
            this.$emit('close');
        },
    },
};
</script>

<style scoped>

</style>