import errorMessage from '@/utils/messages/errorMessage';
import axios from 'axios';
import constants from '@/utils/constants';
import store from '@/store/store';

const authHttp = axios.create({
    baseURL: constants.ENOTARIUS_API_URL,
    timeout: 60000,
});
authHttp.interceptors.request.use(
    async config => {
        let localStore = window.localStorage.getItem('localStore');
        if (localStore) {
            let localStoreObject = JSON.parse(localStore);
            if (localStoreObject.auth.token)
                config.headers['Authorization'] = 'Bearer ' +
                    localStoreObject.auth.token;
            config.headers['Accept-Language'] = localStoreObject.locale.globalLocale;
        }
        if (!config.headers['Accept'])
            config.headers['Accept'] = 'application/json';
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

async function checkBeforeAuth(data) {
    return await authHttp.post('/check_before_auth', data).then((response) => {
        if (response.status === 200 && response.data === 'Ok')
            return true;
        else {
            errorMessage(response.data);
            return false;
        }
    }).catch((error) => {
        errorMessage(error.response.data);
        return false;
    });
}

async function auth(data) {
    return await authHttp.post('/auth', data).then((response) => {
        if (response.status === 200 && response.data.resultCode === 1) {
            store.dispatch('auth/updateToken', response.data.token);
            store.dispatch('user/updateUser', response.data.user);
        }
        return response;
    }).catch((error) => {
        errorMessage(error.response.data);
    });
}

export default {
    checkBeforeAuth: checkBeforeAuth,
    auth: auth,
};