<script>
import SuccessDialogComponent from '@/components/dialogs/SuccessDialogComponent.vue';
import globalMixin from '@/mixins/globalMixin';
import OfferAcceptDialogComponent from '@/components/dialogs/offeer_accept/OfferAcceptDialogComponent.vue';
import ErrorDialogComponent from '@/components/dialogs/ErrorDialogComponent.vue';
import NotificationDialogComponent from '@/components/dialogs/NotificationDialogComponent.vue';
import NotifyWarningDialogComponent from '@/components/dialogs/NotifyWarningDialogComponent.vue';

export default {
    name: 'MixinComponent',
    mixins: [globalMixin],
    components: {
        NotifyWarningDialogComponent,
        NotificationDialogComponent,
        ErrorDialogComponent,
        OfferAcceptDialogComponent,
        SuccessDialogComponent,
    },
    mounted() {
        if (this.isAuthenticated && this.currentUser && this.currentUser.termsofuse != null && this.currentUser.termsofuse !== 1)
            this.checkTermsofuse();
        this.$root.$on('showNotifyWarningDialog', () => this.showNotifyWarningDialog());
    },
    methods: {
        showSuccessDialog() {
            this.$refs.authSuccessDialogComponentRef.showSuccessDialog();
        },
        showErrorDialog(content) {
            this.$refs.authErrorDialogComponentRef.showErrorDialog(content);
        },
        showNotificationDialog() {
            this.$refs.notificationDialogRef.showNotificationDialog();
        },
        checkTermsofuse() {
            this.$refs.offerAcceptDialogComponentRef.showOfferAcceptDialog(true);
        },
        showNotifyWarningDialog() {
            this.$refs.notifyWarningDialogRef.showNotifyWarningDialog();
        },
    },
};
</script>

<template>
    <div>
        <SuccessDialogComponent
            ref="authSuccessDialogComponentRef"
            @checkTermsofuse="checkTermsofuse"
        />

        <ErrorDialogComponent
            ref="authErrorDialogComponentRef"
            @confirm="login"
        />

        <NotificationDialogComponent
            ref="notificationDialogRef"
            :title="$t('technical_work_info.title')"
            :content="$t('technical_work_info.content')"
            content-style="color:red"
        />

        <NotifyWarningDialogComponent
            ref="notifyWarningDialogRef"
        />

        <OfferAcceptDialogComponent
            ref="offerAcceptDialogComponentRef"
        />
    </div>
</template>

<style scoped>

</style>