import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import mode from './modules/mode';
import locale from './modules/locale';
import auth from './modules/auth';
import user from './modules/user';
import breadcrumb from './modules/breadcrumb';
import redirect_url from '@/store/modules/redirect_url';

Vue.use(Vuex);

const vuexStore = new VuexPersist({
    key: 'localStore',
    storage: window.localStorage,
    reducer: (state) => ({
        redirectUrl: state.redirectUrl,
        mode: state.mode,
        locale: state.locale,
        auth: state.auth,
        user: state.user,
    }),
});

export default new Vuex.Store({
    modules: {
        redirectUrl: redirect_url,
        mode: mode,
        locale: locale,
        auth: auth,
        user: user,
        breadcrumb: breadcrumb,
    },
    plugins: [
        vuexStore.plugin,
    ],
});
