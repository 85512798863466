import iziToast from "izitoast";
import 'izitoast/dist/css/iziToast.css'
import i18n from "../../i18n";

export default function errorMessage(message, title = null, timeout = 20000) {
    return iziToast.error({
        backgroundColor: '#ef5350',
        layout: 2,
        title: title ? title : i18n.t("title_error_message"),
        titleColor: '#F5F5F5',
        message: message,
        messageColor: '#F5F5F5',
        position: 'topCenter',
        transitionIn: 'bounceInDown',
        transitionOut: 'flipOutX',
        close: false,
        closeOnClick: true,
        timeout: timeout
    });
}

