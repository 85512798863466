import Vue from "vue";
import successMessage from "./utils/messages/successMessage";
import errorMessage from "./utils/messages/errorMessage";
import axiosApi from "./api/axiosApi";
import authApi from "@/service/auth";
import store from "./store/store";
import numeral from 'numeral'

//axios
Vue.prototype.$checkBeforeAuth= authApi.checkBeforeAuth;
Vue.prototype.$auth = authApi.auth;
Vue.prototype.$getMethod = axiosApi.getMethod;
Vue.prototype.$postMethod = axiosApi.postMethod;
Vue.prototype.$downloadFileMethod = axiosApi.downloadFileMethod;

//components
Vue.prototype.$successMessage = successMessage;
Vue.prototype.$errorMessage = errorMessage;
// models
Vue.prototype.$User = store.getters["user/getUser"];
Vue.prototype.$Roles = store.getters["user/getRoles"];
// methods
Vue.prototype.$hasRole = store.getters["user/hasRole"];
//libs
Vue.prototype.$numeral = numeral