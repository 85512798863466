import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import store from '@/store/store';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
    {
        path: '/index',
        alias: '/',
        component: () => import(/* webpackChunkName: "main" */ '../views/BrowserView'),
        children: [
            {
                path: '/index',
                alias: '/',
                name: 'main',
                component: () => import(/* webpackChunkName: "main" */ '../views/MainView'),
                meta: {
                    breadcrumb: [
                        {name: 'app_bar.index'},
                    ],
                },
            },
            {
                path: '/',
                component: () => import(/* webpackChunkName: "dict" */ '../views/ContentView'),
                children: [
                    {
                        path: 'na_details',
                        name: 'na_details',
                        component: () => import(/* webpackChunkName: "na_details" */ '../views/na_details/NaDetailsView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'app_bar.na_details', to: '/na_details?page=1'},
                            ],
                        },
                    },
                    {
                        path: 'na_details/:id',
                        name: 'na_details.details',
                        component: () => import(/* webpackChunkName: "na_details.details" */ '../views/na_details/NaDetailsDetailsView.vue'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'app_bar.na_details', to: '/na_details?page=1'},
                            ],
                        },
                    },
                    {
                        path: 'na_details/calc/:nadid',
                        name: 'na_details.calc',
                        component: () => import(/* webpackChunkName: "na_details.calc" */ '../views/calc/CalcView.vue'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'app_bar.na_details', to: '/na_details?page=1'},
                            ],
                        },
                    },
                    {
                        path: 'notary',
                        name: 'notary',
                        component: () => import(/* webpackChunkName: "notary" */ '../views/notary/NotaryView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'app_bar.notary', to: '/notary?page=1'},
                            ],
                        },
                    },
                    {
                        path: 'notary/:id',
                        name: 'notary.details',
                        component: () => import(/* webpackChunkName: "notary.details" */ '../views/notary/NotaryDetailsView.vue'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'app_bar.notary', to: '/notary?page=1'},
                            ],
                        },
                    },
                    {
                        path: 'equeue',
                        name: 'equeue',
                        component: () => import(/* webpackChunkName: "equeue" */ '../views/equeue/EqueueView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'app_bar.equeue', to: '/page=1'},
                            ],
                            needAuth: true,
                        },
                    },
                    {
                        path: 'statistics',
                        name: 'statistics',
                        component: () => import(/* webpackChunkName: "statistics" */ '../views/statistics/StatisticsView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'statistic', to: '/statistics'},
                            ],
                        },
                    },
                    {
                        path: 'search_proxy',
                        name: 'search_proxy',
                        component: () => import(/* webpackChunkName: "search_proxy" */ '../views/proxy/SearchProxyView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'search_proxy.title', to: '/search_proxy'},
                            ],
                        },
                    },
                    {
                        path: 'search_inheritcase',
                        name: 'search_inheritcase',
                        component: () => import(/* webpackChunkName: "search_inheritcase" */ '../views/inheritcase/SearchInheritCaseView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {
                                    name: 'search_inheritcase.title',
                                    to: '/search_inheritcase',
                                },
                            ],
                        },
                    },
                    {
                        path: 'search_ban',
                        name: 'search_ban',
                        component: () => import(/* webpackChunkName: "search_ban" */ '../views/ban/SearchBanView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'search_ban.title', to: '/search_ban'},
                            ],
                            needAuth: true,
                        },
                    },
                    {
                        path: 'update_dict',
                        name: 'update_dict',
                        component: () => import(/* webpackChunkName: "update_dict" */ '../views/update_dict/UpdateDictView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'update_dict_page_title', to: '/update_dict'},
                            ],
                        },
                    },
                    {
                        path: 'search_notarial_act',
                        name: 'search_notarial_act',
                        component: () => import(/* webpackChunkName: "search_notarial_acts" */ '../views/notarial_act/SearchNotarialActView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {
                                    name: 'app_bar.search_notarial_act',
                                    to: '/search_notarial_act',
                                },
                            ],
                        },
                    },
                    {
                        path: 'enotarial_act/:uuid',
                        name: 'notarial_act.details',
                        component: () => import(/* webpackChunkName: "notarial_act.details" */ '../views/simplified/ENotarialActsDetailsView.vue'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'notarial_act.my.title', to: '/my_notarial_act'},
                            ],
                            needAuth: true,
                        },
                    },
                    {
                        path: 'admin/users',
                        name: 'admin.users',
                        component: () => import(/* webpackChunkName: "admin.users" */ '../views/admin/UsersListView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'app_bar.admin_users', to: '/admin/users'},
                            ],
                        },
                    },
                    {
                        path: 'admin/users/:id',
                        name: 'admin.users.details',
                        component: () => import(/* webpackChunkName: "admin.users.details" */ '../views/admin/UsersDetailsView.vue'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'app_bar.admin_users', to: '/admin/users'},
                            ],
                        },
                    },
                    {
                        path: 'profile',
                        name: 'profile',
                        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'profile.title', to: '/profile'},
                            ],
                        },
                    },
                    {
                        path: 'video_testing',
                        name: 'video_testing',
                        component: () => import(/* webpackChunkName: "video_testing" */ '../views/video_testing/VideoTestingView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                            ],
                        },
                    },
                    {
                        path: '403',
                        name: '403',
                        component: () => import(/* webpackChunkName: "403" */ '../views/403'),
                    },
                    {
                        path: '404',
                        name: '404',
                        component: () => import(/* webpackChunkName: "404" */ '../views/404'),
                    },
                ],
            },
            {
                path: '/my_notarial_act',
                component: () => import(/* webpackChunkName: "dict" */ '../views/ContentView'),
                meta: {
                    needAuth: true,
                },
                children: [
                    {
                        path: '/',
                        name: 'my_notarial_act',
                        component: () => import(/* webpackChunkName: "my_notarial_act" */ '../views/notarial_act/MyNotarialActsView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {
                                    name: 'notarial_act.my.title',
                                    to: '/my_notarial_act?page=1',
                                },
                            ],
                        },
                    },
                    {
                        path: ':rid',
                        name: 'my_notarial_act.details',
                        component: () => import(/* webpackChunkName: "my_notarial_act.details" */ '../views/notarial_act/MyNotarialActsDetailView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'notarial_act.my.title', to: '/my_notarial_act'},
                            ],
                        },
                    },
                    {
                        path: ':rid/member/:id',
                        name: 'my_notarial_act.member',
                        component: () => import(/* webpackChunkName: "my_notarial_act.member" */ '../views/notarial_act/NotarialActMemberView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'notarial_act.my.title', to: '/my_notarial_act'},
                            ],
                        },
                    },
                    {
                        path: ':rid/auto/:id',
                        name: 'my_notarial_act.auto',
                        component: () => import(/* webpackChunkName: "my_notarial_act.auto" */ '../views/notarial_act/NotarialActAutoView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'notarial_act.my.title', to: '/my_notarial_act'},
                            ],
                        },
                    },
                    {
                        path: ':rid/realty/:id',
                        name: 'my_notarial_act.realty',
                        component: () => import(/* webpackChunkName: "my_notarial_act.realty" */ '../views/notarial_act/NotarialActRealtyView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'notarial_act.my.title', to: '/my_notarial_act'},
                            ],
                        },
                    },
                    {
                        path: ':rid/other/:id',
                        name: 'my_notarial_act.other',
                        component: () => import(/* webpackChunkName: "my_notarial_act.other" */ '../views/notarial_act/NotarialActOtherView'),
                        meta: {
                            breadcrumb: [
                                {name: 'app_bar.index', to: '/index'},
                                {name: 'notarial_act.my.title', to: '/my_notarial_act'},
                            ],
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/kiosk',
        name: 'kiosk',
        component: () => import(/* webpackChunkName: "kiosk" */ '../views/KioskView.vue'),
        children: [
            {
                path: 'auth',
                name: 'kiosk.auth',
                component: () => import(/* webpackChunkName: "kiosk.auth" */ '../views/kiosk/KioskAuthView.vue'),
            },
            {
                path: '/kiosk',
                name: 'kiosk.main',
                component: () => import(/* webpackChunkName: "kiosk.main" */ '../views/kiosk/KioskMainView.vue'),
            },
            {
                path: '/kiosk',
                component: () => import(/* webpackChunkName: "kiosk.main" */ '../views/kiosk/KioskContentView.vue'),
                children: [
                    {
                        path: 'enotarial_act/:uuid',
                        name: 'kiosk.notarial_act.details',
                        component: () => import(/* webpackChunkName: "kiosk.notarial_act.details" */ '../views/simplified/ENotarialActsDetailsView.vue'),
                    },
                    {
                        path: 'search_proxy',
                        name: 'kiosk.search_proxy',
                        component: () => import(/* webpackChunkName: "kiosk.search_proxy" */ '../views/proxy/SearchProxyView'),
                    },
                    {
                        path: 'search_inheritcase',
                        name: 'kiosk.search_inheritcase',
                        component: () => import(/* webpackChunkName: "kiosk.search_inheritcase" */ '../views/inheritcase/SearchInheritCaseView'),
                    },
                    {
                        path: 'search_ban',
                        name: 'kiosk.search_ban',
                        component: () => import(/* webpackChunkName: "kiosk.search_ban" */ '../views/ban/SearchBanView'),
                    },
                    {
                        path: 'equeue',
                        name: 'kiosk.equeue',
                        component: () => import(/* webpackChunkName: "kiosk.equeue" */ '../views/equeue/EqueueView'),
                    },
                    {
                        path: 'my_notarial_act',
                        name: 'kiosk.my_notarial_act',
                        component: () => import(/* webpackChunkName: "kiosk.my_notarial_act" */ '../views/notarial_act/MyNotarialActsView'),
                    },
                    {
                        path: '403',
                        name: 'kiosk.403',
                        component: () => import(/* webpackChunkName: "403" */ '../views/403'),
                    },
                    {
                        path: '404',
                        name: 'kiosk.404',
                        component: () => import(/* webpackChunkName: "404" */ '../views/404'),
                    },
                ],
            },
            {
                path: '*',
                redirect: '/kiosk/404',
            },
        ],
    },
    {
        path: '*',
        redirect: '/404',
    },

];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    // console.log('😂 from ' + from.path + ' ->' + ' to ' + to.path);
    let isAuthenticated = store.getters['auth/isAuthenticated'];

    if (to.fullPath.includes('kiosk')) {
        store.dispatch('mode/updateMode', 'kiosk');

        if (!isAuthenticated && to.name !== 'kiosk.auth') {
            if (to.path)
                store.dispatch('redirectUrl/updateRedirectUrl', to.path);
            next({name: 'kiosk.auth'});
        }
    } else {
        store.dispatch('mode/updateMode', 'browser');

        if (to.path && !isAuthenticated && to.name !== 'main')
            store.dispatch('redirectUrl/updateRedirectUrl', to.path);
    }

    next();
    window.scrollTo(0, 0);
});

export default router;