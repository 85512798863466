import Vue from 'vue';
import Inputmask from 'inputmask';

Vue.directive('mask', {
    bind: function(el, binding) {

        let type = binding.value.type;
        let length = binding.value.length;
        let casing = binding.value.casing;

        let mask = null;
        let regex;
        let placeholder = '';

        if (!type && length)
            regex = '.{0,' + length + '}';

        if (type === 'letter')
            regex = '([A-Za-zЁёА-я]{1}){0,' + length + '}';
        else if (type === 'number')
            regex = '\\d{0,' + length + '}';
        else if (type === 'inn')
            regex = '\\d{9}';
        else if (type === 'pin')
            regex = '\\d{14}';
        else if (type === 'mac') {
            regex = '[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}';
            placeholder = '__:__:__:__:__:__';
        } else if (type === 'date') {
            regex = '\\d{2}\\.\\d{2}\\.\\d{4}';
            placeholder = 'dd.mm.yyyy';
        } else if (type === 'incomplete_date') {
            regex = '(XX|xx|\\d{2})\\.(XX|xx|\\d{2})\\.\\d{4}';
        } else if (type === 'picker') {
            regex = '\\d{2}\\.\\d{2}\\.\\d{4} \\d{2}\\:\\d{2}:\\d{2}';
            placeholder = 'dd.mm.yyyy HH:mm:ss';
        } else if (type === 'techserial')
            regex = '[A-Za-z]{5}';
        else if (type === 'autoregnum')
            regex = '[A-Za-z0-9]{15}';
        else if (type === 'agrotechserial')
            regex = '[A-Za-zЁёА-я\\-]{5}';
        else if (type === 'cadastrenum')
            mask = binding.value.cadmask;
        else if (type === 'cadnum')                   //для упрощенных нот.действий
            regex = '[0-9\\:/]{50}';
        else if (type === 'phone_number')
            mask = '(99) 999 99 99';
        else if (type === 'cardnum')
            regex = '\\d{4} \\d{4} \\d{4} \\d{4}';
        else if (type === 'cardexpire')
            regex = '(0[1-9]|1[012])/\\d{2}';
        else if (type === 'passserial')
            regex = '[A-Za-z\\-]{2}';

        if (type === 'currency') {
            Inputmask({
                alias: 'currency',
                prefix: '',
                placeholder: '',
                groupSeparator: ' ',
                digits: 2,
                digitsOptional: true,
            }).mask(el.getElementsByTagName('input'));
        } else {
            Inputmask({
                mask: mask,
                regex: regex,
                placeholder: placeholder,
                casing: casing,
            }).mask(el.getElementsByTagName('input'));
        }
    },
    update(el, binding, vnode) {
        if (binding && binding.value) {
            if (binding.value.type === 'cadastrenum') {
                if (vnode.data.model.value)
                    vnode.componentInstance.$emit('input',
                        Inputmask.format(vnode.data.model.value,
                            {mask: binding.value.cadmask}));
                Inputmask({
                    mask: binding.value.cadmask,
                }).mask(el.getElementsByTagName('input'));
            } else if (binding.value.type === 'passnum') {
                let length;
                if (binding.value.passtypeid != null) {
                    if (binding.value.passtypeid === 0) {
                        length = '{6,7}';
                        if (vnode.data.model.value)
                            vnode.componentInstance.$emit('input',
                                vnode.data.model.value.substring(0, 7));
                    } else if ([
                        1,
                        6,
                        8,
                        46,
                        47,
                        48,
                        60,
                        1001,
                        1002,
                        1003,
                        1004,
                        20,
                        70].includes(binding.value.passtypeid)) {
                        length = '{7}';
                        if (vnode.data.model.value)
                            vnode.componentInstance.$emit('input',
                                vnode.data.model.value.substring(0, 7));
                    } else
                        length = '{0,20}';
                } else
                    length = '{0,20}';
                Inputmask({
                    regex: '\\d' + length,
                    casing: 'upper',
                }).mask(el.getElementsByTagName('input'));
            }
        }
    },
});