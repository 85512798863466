import User from '@/model/user';

export default {
    namespaced: true,
    state: {
        user: null,
    },
    getters: {
        getUser(state) {
            if (state.user !== null)
                return new User(state.user.id,
                    state.user.userid,
                    state.user.name,
                    state.user.surname,
                    state.user.lastname,
                    state.user.fullname,
                    state.user.typeid,
                    state.user.gender,
                    state.user.birthdate,
                    state.user.birthcountry,
                    state.user.inn,
                    state.user.passserial,
                    state.user.pin,
                    state.user.passnum,
                    state.user.issueorg,
                    state.user.issuedate,
                    state.user.exprdate,
                    state.user.citizenship,
                    state.user.nationality,
                    state.user.phone,
                    state.user.email,
                    state.user.regionid,
                    state.user.regionru,
                    state.user.regionuz,
                    state.user.districtid,
                    state.user.districtru,
                    state.user.districtuz,
                    state.user.regaddress,
                    state.user.actaddress,
                    state.user.orginn,
                    state.user.orgname,
                    state.user.valid,
                    state.user.termsofuse,
                    state.user.roles,
                );
            else
                return null;
        },
        getRoles(state) {
            if (state.user && state.user.roles)
                return state.user.roles;
            else
                return null;
        },
        hasRole: (state) => (...roles) => {
            if (state.user && state.user.roles)
                return state.user.roles.some(role => roles.indexOf(role) >= 0);
            return false;
        },
    },
    mutations: {
        UPDATE_USER(state, newUser) {
            state.user = newUser;
        },
    },
    actions: {
        updateUser({commit}, newUser) {
            commit('UPDATE_USER', newUser);
        },
    },
};
