<script>
import globalMixin from '@/mixins/globalMixin';

export default {
    name: 'SuccessDialogComponent',
    mixins: [globalMixin],
    data: () => ({
        showDialog: false,
    }),
    methods: {
        showSuccessDialog() {
            if (this.isKiosk)
                this.$router.push({name: 'kiosk.main'});
            this.showDialog = true;
        },
        closeSuccessDialog() {
            this.showDialog = false;
            if (this.isAuthenticated) {
                if (this.currentUser && this.currentUser.termsofuse != null) {
                    if (this.currentUser.termsofuse === 1) {
                        if (this.isBrowser) {
                            let routeQuery = this.$route.query;
                            if (routeQuery.pin && routeQuery.passserial && routeQuery.passnum)
                                this.$router.push('/enotarial_act/-1');
                            else if (this.getRedirectUrl) {
                                if (this.$route.path !== this.getRedirectUrl)
                                    this.$router.push(this.getRedirectUrl);
                                this.updateRedirectUrl(null);
                            } else
                                this.$router.push('/my_notarial_act');
                        }
                        if (this.isKiosk) {
                            if (this.getRedirectUrl) {
                                if (this.$route.path !== this.getRedirectUrl)
                                    this.$router.push(this.getRedirectUrl);
                                this.updateRedirectUrl(null);
                            }
                            this.$root.$emit('showNotifyWarningDialog');
                        }
                    } else
                        this.$emit('checkTermsofuse');
                }
            }
        },
    },
};
</script>

<template>
    <v-dialog
        v-model="showDialog"
        :max-width="$vuetify.breakpoint.mobile ? '90%' :'30%'"
        persistent
    >
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col class="text-center">
                        <v-icon color="primary" size="100">
                            mdi-check-circle
                        </v-icon>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-subtitle>
                <v-row>
                    <v-col class="text-center">
                        <span class="text-h4">{{ $t('auth.success.title') }}</span>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col class="text-center">
                        <span style="font-size: large;">{{ $t('auth.success.content') }}</span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    class="mb-4"
                    color="primary"
                    block
                    style="text-transform: none; font-size: large;"
                    large
                    @click="closeSuccessDialog"
                >
                    {{ $t('auth.success.btn') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>

</style>