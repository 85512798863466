import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store/store';
import i18n from './i18n';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vuetify from './plugins/vuetify';
import moment from 'moment';
import VueMoment from 'vue-moment';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import VueMeta from "vue-meta";
import VueGtag from "vue-gtag";
import 'moment/locale/ru';
import 'moment/locale/uz';
import 'leaflet/dist/leaflet.css';

import "./assets/css/style.css";
import './assets/font/index.css';
import './prototypes';
import './directives';
import './filters/filters';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueMoment, {moment});
Vue.component('sync-loader', SyncLoader);

Vue.use(VueMeta, {
    keyName: 'head'
})

Vue.use(VueGtag, {
    config: { id: "G-73LDSQ6MVY" }
}, router);

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App),
    mounted() {
        this.$moment.locale(store.getters['locale/getGlobalLocale']);
    }
}).$mount('#app');