import Vue from "vue";
import numeral from 'numeral';
import moment from "moment";

Vue.filter('number', function (value) {
    if (value != null)
        return numeral(value).format('0,0').replace(/,/g, ' ');
    else return '';
});
Vue.filter('decimal', function (value) {
    if (value != null)
        return numeral(value).format('0,0.00').replace(/,/g, ' ');
    else return '';
});
Vue.filter('DD_MM_YYYY', (value) => {
    if (value != null)
        return moment(value, "YYYY-MM-DD HH:mm:ss.S").format("DD.MM.YYYY");
    else return '';
});