export default class User {
    constructor(
        id,
        userid,
        name,
        surname,
        lastname,
        fullname,
        typeid,
        gender,
        birthdate,
        birthcountry,
        inn,
        passserial,
        pin,
        passnum,
        issueorg,
        issuedate,
        exprdate,
        citizenship,
        nationality,
        phone,
        email,
        regionid,
        regionru,
        regionuz,
        districtid,
        districtru,
        districtuz,
        regaddress,
        actaddress,
        orginn,
        orgname,
        valid,
        termsofuse,
        roles,
    ) {
        this.id = id;
        this.userid = userid;
        this.name = name;
        this.surname = surname;
        this.lastname = lastname;
        this.fullname = fullname;
        this.typeid = typeid; // 1 - физ,  2 - юр
        this.gender = gender; // 1 - муж, 2 - жен
        this.birthdate = birthdate;
        this.birthcountry = birthcountry;
        this.inn = inn;
        this.pin = pin;
        this.passserial = passserial;
        this.passnum = passnum;
        this.issueorg = issueorg;
        this.issuedate = issuedate;
        this.exprdate = exprdate;
        this.citizenship = citizenship;
        this.nationality = nationality;
        this.phone = phone;
        this.email = email;
        this.regionid = regionid;
        this.regionru = regionru;
        this.regionuz = regionuz;
        this.districtid = districtid;
        this.districtru = districtru;
        this.districtuz = districtuz;
        this.regaddress = regaddress;
        this.actaddress = actaddress;
        this.orginn = orginn;
        this.orgname = orgname;
        this.valid = valid;
        this.termsofuse = termsofuse;
        this.roles = roles;
    }
}