<script>
import globalMixin from '@/mixins/globalMixin';
import {mapActions} from 'vuex';

export default {
    name: 'OfferAcceptDialogComponent',
    mixins: [globalMixin],
    data: () => ({
        offerAcceptDialog: false,
        persistent: false,
    }),
    methods: {
        ...mapActions({
            updateToken: 'auth/updateToken',
            updateUser: 'user/updateUser',
        }),
        showOfferAcceptDialog(_persistent) {
            if (_persistent)
                this.persistent = _persistent;
            this.offerAcceptDialog = true;
        },
        chekTokenBeforeDisagree() {
            this.$getMethod(this, '/check_token').then((response) => {
                if (response && response.status === 200)
                    this.disagree();
                else {
                    this.updateToken(null);
                    this.updateUser(null);
                }
            }).catch(error => console.log(error));
        },
        chekTokenBeforeAccept() {
            this.$getMethod(this, '/check_token').then((response) => {
                if (response && response.status === 200)
                    this.accept();
                else {
                    this.updateToken(null);
                    this.updateUser(null);
                }
            }).catch(error => console.log(error));
        },
        disagree() {
            this.offerAcceptDialog = false;
            this.$postMethod(this, '/offer_accept', null, {
                params: {
                    action: 0,
                },
            }).then(() => {
                this.updateToken(null);
                this.updateUser(null);

                if (this.isBrowser && this.$router.currentRoute.fullPath !== '/')
                    this.$router.push({path: '/'});

                if (this.isKiosk)
                    this.$router.push({name: 'kiosk.auth'});
            }).catch(error => console.error(error));
        },
        accept() {
            this.offerAcceptDialog = false;
            this.$postMethod(this, '/offer_accept', null, {
                params: {
                    action: 1,
                },
            }).then(() => {
                let user = this.getCurrentUser;
                user.termsofuse = 1;
                this.updateUser(user);
                if (this.isBrowser) {
                    if (this.getRedirectUrl) {
                        if (this.$route.path !== this.getRedirectUrl)
                            this.$router.push(this.getRedirectUrl);
                        this.updateRedirectUrl(null);
                    } else
                        this.$router.push('/my_notarial_act');
                }
            }).catch(error => console.error(error));
        },
    },
};
</script>

<template>
    <v-row justify="center">
        <v-dialog
            v-model="offerAcceptDialog"
            :max-width="$vuetify.breakpoint.mobile ? '100%' : '60%'"
            :persistent="persistent"
        >
            <v-card>
                <v-card-title class="text-h5 justify-center">
                    <span>Фойдаланиш шартлари</span>
                </v-card-title>

                <v-card-text>
                    <div style="font-size: 15px">
                        <div class="text-center"><h3>Видеоконфренц алоқа орқали айрим нотариал ҳаракатларни онлайн
                            расмийлаштириш учун</h3></div>
                        <div class="text-center"><h3><strong>ТАЛАБЛАР</strong></h3></div>
                        <p>1. Ушбу билан Адлия вазирлиги (офертани таклиф қилувчи) мазкур сайт орқали қуйида кўрсатилган
                            шартлар асосида шартнома (оферта) тузишни таклиф қилади ва ушбу оферта билан тасдиқланган
                            шартларнинг бажарилиши ушбу шартноманинг тузилиши эканлиги ва юридик аҳамиятга эга
                            эканлигини билдиради.</p>
                        <p>2. Офертани қабул қилувчи тарафлар уни қабул қилиш орқали шартноманинг тузилиши ҳамда юридик
                            аҳамиятга эга эканлигини англайдилар ва тасдиқлайдилар.</p>
                        <p>3. Мазкур офертада қуйидаги асосий тушунча ва атамалардан фойдаланилади:
                            <ul class="mt-0" style="list-style-type: none;">
                                <li><strong>оммавий оферта</strong> – оферта таклиф қилувчининг шартнома шартлари,
                                    талаблари,
                                    тарафларнинг ҳуқуқ ва мажбуриятлари бўйича таклифи;
                                </li>
                                <li><strong>акцепт</strong> – оферта шартномаси шартлари ва талабларини шахснинг уни
                                    қабул
                                    қилганлиги ҳақидаги жавоби;
                                </li>
                                <li><strong>акцептловчи</strong> – мазкур оферта талаб ва шартларини қабул қилувчи шахс.
                                </li>
                            </ul>
                        </p>
                        <p>4. Нотариал хизматлардан фойдаланишдан аввал аризачи (жисмоний шахс ёки юридик шахс вакили)
                            мазкур тартиб билан тўлиқ танишиб чиқиши, тартиб ва шартлардан тўлиқ ёки қисман норози
                            бўлганда, аризачи хизматдан фойдаланишдан бош тортиши зарур.</p>
                        <p>5. Акцептловчи барча шахслар нотариал ҳаракатларни ўзи хақидаги маълумотларни ошкор этмасдан
                            расмийлаштириш мумкин эмаслигини тушунади ва ўзининг шахсига доир маълумотларидан амалга
                            ошириладиган нотариал ҳаракат учун фойдаланилишига розилигини англатади ҳамда нотариусга
                            керакли маълумотларни тақдим этади.
                            <br>Оферта билан танишиб, офертада таклиф этилган барча шартларни қабул қилганлигини
                            билдиради.
                        </p>
                        <p>6. Акцептловчи ушбу талабларга розилигини билдириш орқали Ўзбекистон Республикаси Фуқаролик
                            Кодексининг 367, 369, 370-моддаларига мувофиқ хизмат кўрсатиш шартномасини тузган
                            ҳисобланади.</p>
                        <p>7. Шартномани (келишувни) расмийлаштириш нотариат соҳасини тартибга солувчи норматив-ҳуқуқий
                            ҳужжатлар талабларига мувофиқ амалга оширилади.</p>
                        <p>Агарда мулк умумий мулк бўлиб, мулкдорлардан бири вафот этган бўлса, нотариал ҳаракат
                            анъанавий (нотариал идорага бевосита мурожаат қилиш орқали) шаклда амалга оширилади.</p>
                        <p>8. Акцептловчи тегишли маълумотларни тўлиқ ва аниқ кўрсатиши зарур ҳамда ўзи томонидан тақдим
                            этилган маълумотларнинг ҳаққонийлиги учун жавобгардир.</p>
                        <p>9. Нотариус ва нотариал ҳаракат иштирокчилари нотариал ҳаракатни расмийлаштириш жараёнида
                            махфийликка риоя қилиш мажбуриятини олади Бунда иштирокчилар саволларга аниқ ва тўғри жавоб
                            бериши шарт.</p>
                        <p>10. Видеоконфренц алоқа орқали нотариал ҳаракатни расмийлаштириш жараёни акс этган видеоёзув
                            “Нотариус” автоматлаштирилган ахборот тизимида сақланиб қолади.</p>
                        <p>11. Акцептловчи нотариал хизматлар кўрсатувчи нотариусни ўзи эркин танлайди ёки автоматик
                            равишда танланган нотариус хизматидан фодаланади. <br>Акцептловчи нотариал ҳаракат
                            расмийлаштиришнинг исталган босқичида нотариус хизматларидан бош
                            тортишга ҳақли.</p>

                        <div class="text-center"><h3>Нотариал хизматлардан видеоконфренц алоқа орқали автотранспорт
                            воситасини бошқа шахсга ўтказиш билан боғлиқ
                            битимларни онлайн расмийлаштириш учун
                        </h3></div>
                        <div class="text-center"><h3><strong>ТАЛАБЛАР</strong></h3></div>
                        <p>1. Ушбу билан Адлия вазирлиги (офертани таклиф қилувчи) мазкур сайт орқали қуйида кўрсатилган
                            шартлар асосида шартнома (оферта) тузишни таклиф қилади ва ушбу оферта билан тасдиқланган
                            шартларнинг бажарилиши ушбу шартноманинг тузилиши эканлиги ва юридик аҳамиятга эга
                            эканлигини билдиради.</p>
                        <p>2. Офертани қабул қилувчи тарафлар уни қабул қилиш орқали шартноманинг тузилиши ҳамда юридик
                            аҳамиятга эга эканлигини англайдилар ва тасдиқлайдилар.</p>
                        <p>3. Мазкур Офертада қуйидаги асосий тушунча ва атамалардан фойдаланилади:
                            <ul class="mt-0" style="list-style-type: none;">
                                <li><strong>оммавий оферта</strong> – оферта таклиф қилувчининг шартнома шартлари,
                                    талаблари,
                                    тарафларнинг ҳуқуқ ва мажбуриятлари бўйича таклифи;
                                </li>
                                <li><strong>акцепт</strong> – оферта шартномаси шартлари ва талабларини шахснинг уни
                                    қабул
                                    қилганлиги ҳақидаги жавоби;
                                </li>
                                <li><strong>акцептловчи</strong> – мазкур оферта талаб ва шартларини қабул қилувчи шахс.
                                </li>
                            </ul>
                        </p>
                        <p>
                            4. Нотариал хизматлардан фойдаланишдан аввал аризачи (жисмоний шахс ёки юридик шахс вакили)
                            мазкур тартиб билан тўлиқ танишиб чиқиши, тартиб ва шартлардан тўлиқ ёки қисман норози
                            бўлганда, аризачи хизматдан фойдаланишдан бош тортиши зарур.
                        </p>
                        <p>5. Акцептловчи барча шахслар нотариал ҳаракатларни ўзи хақидаги маълумотларни ошкор этмасдан
                            расмийлаштириш мумкин эмаслигини тушунади ва ўзининг шахсига доир маълумотларидан амалга
                            ошириладиган нотариал ҳаракат учун фойдаланилишига розилигини англатади ҳамда нотариусга
                            керакли маълумотларни тақдим этади. <br>Оферта билан танишиб, офертада таклиф этилган барча
                            шартларни қабул қилганлигини билдиради.</p>
                        <p>6. Акцептловчи ушбу талабларга розилигини билдириш орқали Ўзбекистон Республикаси Фуқаролик
                            Кодексининг 367, 369, 370-моддаларига мувофиқ хизмат кўрсатиш шартномасини тузган
                            ҳисобланади.</p>
                        <p>7. Шартномани (келишувни) расмийлаштириш нотариат соҳасини тартибга солувчи норматив-ҳуқуқий
                            ҳужжатлар талабларига мувофиқ амалга оширилади. <br>Агарда шартнома предмети бўлган
                            автотранспорт воситаси умумий мулкни ташкил этиб, мулкдорлардан бири вафот этган бўлса,
                            нотариал ҳаракат анъанавий (нотариал идорага бевосита мурожаат қилиш орқали) шаклда амалга
                            оширилади.</p>
                        <p>8. Акцептловчи тегишли маълумотларни тўлиқ ва аниқ кўрсатиши зарур ҳамда ўзи томонидан тақдим
                            этилган маълумотларнинг ҳаққонийлиги учун жавобгардир.</p>
                        <p>9. Нотариус ва нотариал ҳаракат иштирокчилари нотариал ҳаракатни расмийлаштириш жараёнида
                            махфийликка риоя қилиш мажбуриятини олади Бунда иштирокчилар саволларга аниқ ва тўғри жавоб
                            бериши шарт.</p>
                        <p>10. Видеоконфренц алоқа орқали нотариал ҳаракатни расмийлаштириш жараёни акс этган видеоёзув
                            “Нотариус” автоматлаштирилган ахборот тизимида сақланиб қолади.</p>
                        <p>11. Акцептловчи нотариал хизматлар кўрсатувчи нотариусни ўзи эркин танлайди ёки автоматик
                            равишда танланган нотариус хизматидан фодаланади. <br>Акцептловчи нотариал ҳаракат
                            расмийлаштиришнинг исталган босқичида нотариус хизматларидан бош тортишга ҳақли.</p>

                    </div>
                </v-card-text>

                <v-card-actions v-if="isAuthenticated"
                                :style="$vuetify.breakpoint.mobile ? 'display: inline-block; text-align: center' : ''">
                    <v-spacer v-if="!$vuetify.breakpoint.mobile"/>
                    <v-btn
                        v-if="currentUser && currentUser.termsofuse != null && currentUser.termsofuse === 0"
                        color="green"
                        outlined
                        @click="chekTokenBeforeAccept"
                    >
                        {{ $t('btn_agree') }}
                    </v-btn>
                    <v-btn
                        color="red"
                        outlined
                        @click="chekTokenBeforeDisagree"
                        style="display: inline-block !important;white-space: normal;word-break: normal;"
                        :class="$vuetify.breakpoint.mobile ? 'ml-0 mt-2' : ''"
                    >
                        {{ $t('btn_disagree_and_logout') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<style scoped>

</style>