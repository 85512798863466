<template>
    <div class="simple-keyboard-container simple-keyboard">
        <div :class="keyboardClass"/>
        <v-btn
            v-if="inputType !== 'number'"
            icon
            color="primary"
            class="simple-keyboard-switch"
            @click="switchLayout"
        >
            <v-icon>mdi-web</v-icon>
        </v-btn>
    </div>
</template>

<script>
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';
import englishLayout from 'simple-keyboard-layouts/build/layouts/english';
import russianLayout from 'simple-keyboard-layouts/build/layouts/russian';

export default {
    name: 'SimpleKeyboard',
    props: {
        keyboardClass: {
            default: 'simple-keyboard',
            type: String,
        },
        input: {
            type: String,
        },
        inputType: {
            type: String,
            default: 'text',
        },
        inputLength: {
            type: String,
            default: '250',
        },
    },
    data: () => ({
        keyboard: null,
        currentLanguage: 'english',
    }),
    mounted() {
        this.keyboard = new Keyboard(this.keyboardClass, {
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
            layout: englishLayout.layout,
            maxLength: parseInt(this.inputLength),
        });

        if (this.inputType === 'number') {
            this.keyboard.setOptions({
                layout: {
                    default: ['1 2 3', '4 5 6', '7 8 9', '_ 0 _', '{bksp}'],
                },
                theme: 'hg-theme-default hg-layout-numeric numeric-theme',
            });
        }
    },
    methods: {
        onChange(input) {
            this.$emit('onChange', input);
        },
        onKeyPress(button) {
            this.$emit('onKeyPress', button);
            if (button === '{shift}' || button === '{lock}')
                this.handleShift();
        },
        handleShift() {
            let currentLayout = this.keyboard.options.layoutName;
            let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

            this.keyboard.setOptions({
                layoutName: shiftToggle,
            });
        },
        switchLayout() {
            this.currentLanguage = this.currentLanguage === 'english' ? 'russian' : 'english';
            const newLayout = this.currentLanguage === 'russian' ? russianLayout : englishLayout;
            this.keyboard.setOptions({
                layout: newLayout.layout,
            });
        },
    },
    watch: {
        input(input) {
            this.keyboard.setInput(input);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.simple-keyboard-container {
    position: absolute;
    z-index: 1000;
    top: 50px;
    left: 50%;
    transform: translate(-400px, 0)
}

.simple-keyboard {
    width: 800px;
    color: rgba(0, 0, 0, 0.6) !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
}

.simple-keyboard-switch {
    position: absolute;
    bottom: 7px;
    right: 5px
}

.numeric-theme {
    max-width: 320px;
    margin: 0 auto;
}
</style>

