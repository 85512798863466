<template>
    <v-dialog
        v-model="showDialog"
        :max-width="$vuetify.breakpoint.mobile ? 100 + '%' : width"
    >
        <v-card>
            <v-card-title :class="$vuetify.breakpoint.mdAndUp ? 'headline' : ''">
                <v-icon left class="mr-3">{{ icon }}</v-icon>
                {{ title }}
                <v-spacer></v-spacer>
                <v-icon
                    v-show="$vuetify.breakpoint.mdAndUp"
                    @click="showDialog = false"
                >
                    mdi-close-thick
                </v-icon>
            </v-card-title>

            <v-divider class="mb-2"></v-divider>

            <v-card-text class="text-lg-body-1" :style="contentStyle" style="text-align: justify">
                <span v-if="html" v-html="content"/>
                <span v-else>{{ content }}</span>
            </v-card-text>

            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>

                <v-btn
                    text
                    class="mr-2"
                    @click="showDialog = false"
                >
                    {{ $t('btn_close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "NotificationDialogComponent",
    data: () => ({
        showDialog: false
    }),
    props: {
        html: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'mdi-alert-circle-outline'
        },
        contentStyle: {
            type: String,
            default: null
        },
        title: String,
        content: String,
        width: {
            default: "40%"
        }
    },
    methods: {
        showNotificationDialog() {
            this.showDialog = true;
        }
    }
}
</script>

<style scoped>

</style>