import {mapActions, mapGetters} from 'vuex';
import constants from '@/utils/constants';
import utils from '@/utils/utils';

export default {
    data: () => ({
        pageTitle: null,
        loginLoader: false,
        offerAcceptDialogComponentRef: null,
    }),
    computed: {
        ...mapGetters({
            getRedirectUrl: 'redirectUrl/getRedirectUrl',
            getMode: 'mode/getMode',
            getGlobalLocale: 'locale/getGlobalLocale',
            getCurrentUser: 'user/getUser',
            hasRole: 'user/hasRole',
            getBreadcrumbValue: 'breadcrumb/getBreadcrumbValue',
            currentUser: 'user/getUser',
            isAuthenticated: 'auth/isAuthenticated',
        }),
        constants() {
            return constants;
        },
        isMobile() {
            return utils.isMobile();
        },
        isBrowser() {
            return this.getMode === 'browser';
        },
        isKiosk() {
            return this.getMode === 'kiosk';
        },
    },
    methods: {
        ...mapActions({
            updateRedirectUrl: 'redirectUrl/updateRedirectUrl',
            updateMode: 'mode/updateMode',
            updateGlobalLocale: 'locale/updateGlobalLocale',
            updateBreadcrumbValue: 'breadcrumb/updateBreadcrumbValue',
        }),
        breadcrumbArray(parentName, parentPath, childName) {
            let breadcrumbArray = [
                {
                    name: parentName,
                    to: parentPath,
                    fromStore: true,
                },
                {
                    name: childName,
                    to: '',
                    fromStore: true,
                },
            ];
            this.fillBreadcrumbArray(breadcrumbArray);
        },
        fillBreadcrumbArray(breadcrumbArray) {
            this.updateBreadcrumbValue(breadcrumbArray);
        },
        fillBreadcrumb(name) {
            if (this.isBrowser) {
                let breadcrumb = {
                    name: name,
                    fromStore: true,
                };
                this.updateBreadcrumbValue(breadcrumb);
            }
        },
        // focusFirstInput(focusRootRef) {
        //     this.$nextTick(() => {
        //         const theElement = this.$refs[focusRootRef];
        //         const input = theElement.querySelector(
        //             'input:not([type=hidden]),textarea:not([type=hidden])');
        //         if (input) {
        //             setTimeout(() => {
        //                 input.focus();
        //             }, 0);
        //         }
        //     });
        // },
        async close() {
            let breadcrumbArray = this.$route.meta.breadcrumb;
            breadcrumbArray = breadcrumbArray.concat(this.getBreadcrumbValue);
            await this.$router.push({
                path: breadcrumbArray[breadcrumbArray.length - 2].to,
            });
        },
        login() {
            if (!this.isAuthenticated)
                this.$root.$emit('showAuthDialog');
        },
    },
};