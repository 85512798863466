import iziToast from "izitoast";
import 'izitoast/dist/css/iziToast.css'
import i18n from "../../i18n";

export default function successMessage(message, noTitle) {
    return iziToast.success({
        backgroundColor: '#42A5F5',
        title: noTitle ? "" : i18n.t("title_success_message"),
        titleColor: '#F5F5F5',
        message: message,
        messageColor: '#F5F5F5',
        position: 'topCenter',
        transitionIn: 'bounceInDown',
        transitionOut: 'flipOutX',
        close: false,
        closeOnClick: true,
    });
}